import * as React from "react";
import { CevdetScreen_ScreenBase } from "./cevdetscreen-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";
import {MainFrame_Screen} from "../mainframe/mainframe";



declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class CevdetScreen_Screen extends CevdetScreen_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              <MainFrame_Screen form={this.props.form} initialValues={{}} screenInputs={this.props.screenInputs} setMasterPageInitLoaded={this.setMasterPageInitLoaded} setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded} isMasterPageInitLoaded={this.state.isMasterPageInitLoaded} isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded} >
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                        
<div id="cevdetscreen_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block","overflow":"auto","minHeight":"calc(100vh - 70px - 60px - 0px - 0px)","maxHeight":"calc(100vh - 70px - 60px - 0px - 0px)","height":"calc(100vh - 70px - 60px - 0px - 0px)"} as any}>
<KRow id="623907" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="256931" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="170202" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":24,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="246396" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="4344" value={ReactSystemFunctions.translate(this.ml, 4344, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":700,"fontSize":"22pt","color":"rgba(0, 0, 0, 1)","lineHeight":"38px"} as any}></Label>
</KCol>

<KCol id="76333" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="656960" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="444628" iconName="notifications_none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"32px","color":"rgba(74, 74, 74, 1)","letterSpacing":"1px"} as any}></Icon>

<KContentMenu id="411681" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KContentMenuPanel id="666551" style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Button id="639557" label={this.state.KuserSelectByUserName?.at?.(0)?.adi ?? undefined} size="middle" loading={false} ghost={false} block={false} htmlType="button" icon="expand_more" iconPosition="right" danger={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Button>
</KContentMenuPanel>

<KContentMenuItem id="878839" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_878839_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 878839, "label",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":16,"paddingRight":16,"paddingBottom":16,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KContentMenuItem>

<KContentMenuItem id="31510" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_31510_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 31510, "label",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":16,"paddingRight":16,"paddingBottom":16,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(208, 2, 27, 1)"} as any}></KContentMenuItem>
</KContentMenu>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="253850" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="874559" xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="495209" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="928827" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="358922" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="509700" xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="663723" value={ReactSystemFunctions.translate(this.ml, 663723, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>

<KCol id="351391" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="190787" iconName="shopping_cart" style={{"borderTopLeftRadius":200,"borderTopRightRadius":200,"borderBottomRightRadius":200,"borderBottomLeftRadius":200,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(116, 222, 250, 0.2)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(22, 177, 212, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>

<KRow id="717574" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="209533" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":16,"paddingRight":16,"paddingBottom":0,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="761986" value={this.state.yillikAdetBilgisi?.at?.(0)?.toplamAdet ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":26,"paddingLeft":0,"maxWidth":"200px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>

<KCol id="135206" xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="323600" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="516550" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="174189" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="325534" xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="160638" value={ReactSystemFunctions.translate(this.ml, 160638, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>

<KCol id="917148" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="95793" iconName="equalizer" style={{"borderTopLeftRadius":200,"borderTopRightRadius":200,"borderBottomRightRadius":200,"borderBottomLeftRadius":200,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(116, 222, 250, 0.2)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(22, 177, 212, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>

<KRow id="202234" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="862181" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":16,"paddingRight":16,"paddingBottom":0,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="797824" value={this.state.tumyilsatisBilgisi?.at?.(0)?.toplamTutar ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":24,"paddingLeft":0,"maxWidth":"200px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>

<KCol id="670732" xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="311450" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="706095" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="133005" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="581729" xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="152077" value={ReactSystemFunctions.translate(this.ml, 152077, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>

<KCol id="243939" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="807472" iconName="point_of_sale" style={{"borderTopLeftRadius":200,"borderTopRightRadius":200,"borderBottomRightRadius":200,"borderBottomLeftRadius":200,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(116, 222, 250, 0.2)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(22, 177, 212, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>

<KRow id="250329" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="327682" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":16,"paddingRight":16,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="456850" value={this.state.sonGunSatis?.at?.(0)?.toplamTutar ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":21,"paddingLeft":0,"maxWidth":"200px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="414620" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="540016" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="473" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="568935" xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="641385" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="956075" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="832439" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="300562" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="218858" value={ReactSystemFunctions.translate(this.ml, 218858, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>

<KCol id="940604" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="263056" direction="horizontal" size={8} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="715658" visibility={this.state.isComp715658Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), false)} iconName="south_east" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(245, 34, 45, 1)","letterSpacing":"1px"} as any}></Icon>

<Icon id="371595" visibility={this.state.isComp371595Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), true)} iconName="north_east" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(68, 205, 19, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="701566" visibility={this.state.isComp701566Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), true)} value={this.state.gunlukFarkBilgisi?.at?.(0)?.degisim ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(82, 196, 26, 1)"} as any}></Label>

<Label id="313332" visibility={this.state.isComp313332Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), false)} value={this.state.gunlukFarkBilgisi?.at?.(0)?.degisim ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(245, 34, 45, 1)"} as any}></Label>

<Label id="45990" visibility={this.state.isComp45990Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), true)} value={ReactSystemFunctions.translate(this.ml, 45990, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(82, 196, 26, 1)"} as any}></Label>

<Label id="170102" visibility={this.state.isComp170102Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.gunlukFarkBilgisi[0]?.artisMi?.toString(), false)} value={ReactSystemFunctions.translate(this.ml, 170102, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(245, 34, 45, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="117066" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_117066_onClick()} } showCursorPointer align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":16,"paddingBottom":16,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="656828" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<BarChart id="647414" dataSource={this.state.gunluktoplamsatis} dataLabelKeyName="tarih" dataDisplayLabelKeyName="tarih" dataTooltipFooterDataLabel="toplam_satis_tutari" firstDataFieldKeyName="toplam_satis_tutari" legend="bottom" leftAxis={this.state.sayigetirTablo?.at?.(0)?.sayilar ?? undefined} rightAxis={true} beginAtZero={false} maxYAxisValue={1000000} showDataLabels={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"maxHeight":"168px","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(155, 155, 155, 1)","lineHeight":"20px"} as any}></BarChart>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>

<KCol id="814293" xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="800203" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="872078" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="866531" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="227784" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="312307" value={ReactSystemFunctions.translate(this.ml, 312307, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="463412" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_463412_onClick()} } showCursorPointer align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":16,"paddingBottom":16,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="614259" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":8,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<MixedChart id="808456" options={this.state.tarihuret} dataxaxisesfield="gunSayisi" options={this.state.dataSource_808456} style={{"height":"168px"} as any}>
<MixedChartLineItem id="243084" options={this.state.kalanchone10TR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(245, 34, 45, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_243084} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="732738" options={this.state.euphorbia10Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" type="line" lineWidth={2} options={this.state.dataSource_732738} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="544846" options={this.state.campanulaGrafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" type="line" lineWidth={2} options={this.state.dataSource_544846} style={{} as any}></MixedChartLineItem>
</MixedChart>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="398351" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="774940" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="135532" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_135532_onClick()} } showCursorPointer align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="641454" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="392068" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":16,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="399802" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="143551" value={ReactSystemFunctions.translate(this.ml, 143551, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"22px","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="243318" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":16,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="345587" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="268570" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="174210" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="889309" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="204717" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(82, 196, 26, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="52699" value={ReactSystemFunctions.translate(this.ml, 52699, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="388695" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="361817" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 0, 0, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="151853" value={ReactSystemFunctions.translate(this.ml, 151853, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="378" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="977487" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(180, 0, 253, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="978576" value={ReactSystemFunctions.translate(this.ml, 978576, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="598125" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="701882" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(24, 144, 255, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="493695" value={ReactSystemFunctions.translate(this.ml, 493695, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="768277" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="375707" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(245, 34, 45, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="173901" value={ReactSystemFunctions.translate(this.ml, 173901, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>

<HorizontalStack id="872223" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="177967" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(250, 219, 20, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="659985" value={ReactSystemFunctions.translate(this.ml, 659985, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>
</KCol>
</KRow>

<MixedChart id="525911" options={this.state.buguneKadarGunler} dataxaxisesfield="gunSayisi" options={this.state.dataSource_525911} style={{} as any}>
<MixedChartLineItem id="276468" options={this.state.kalanchone6TR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(82, 196, 26, 1)" type="line" label="Queen Kalanchoe 10,5" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_276468} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="442160" options={this.state.kalanchone10TR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(245, 34, 45, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_442160} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="646360" options={this.state.euphorbiaTR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(24, 144, 255, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_646360} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="257373" options={this.state.succulent5TR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(250, 219, 20, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_257373} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="407848" options={this.state.succulent8TR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(180, 0, 253, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_407848} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="714685" options={this.state.kesmebuketTR} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(0, 0, 0, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_714685} style={{} as any}></MixedChartLineItem>
</MixedChart>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="813955" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="66881" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="414397" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="768276" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="220321" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":16,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="894910" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.cevdetScreenComponent_894910_onClick()} } showCursorPointer xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="295429" value={ReactSystemFunctions.translate(this.ml, 295429, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"22pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>

<KRow id="761605" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="742192" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="815437" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="172917" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(180, 0, 253, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="266397" value={ReactSystemFunctions.translate(this.ml, 266397, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"80px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="642188" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="558912" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(24, 144, 255, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="490371" value={ReactSystemFunctions.translate(this.ml, 490371, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"120px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="838555" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="653993" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(245, 34, 45, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="356311" value={ReactSystemFunctions.translate(this.ml, 356311, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"80px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="44184" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="427511" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(19, 194, 194, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="349280" value={ReactSystemFunctions.translate(this.ml, 349280, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"150px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"10px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="227390" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="613249" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(239, 111, 40, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="662095" value={ReactSystemFunctions.translate(this.ml, 662095, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"9px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>

<HorizontalStack id="322658" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="637180" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 0, 0, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="186947" value={ReactSystemFunctions.translate(this.ml, 186947, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"110px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="825466" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="167996" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(82, 196, 26, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="551309" value={ReactSystemFunctions.translate(this.ml, 551309, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"90px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>

<HorizontalStack id="26265" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="290462" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(194, 172, 19, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="127610" value={ReactSystemFunctions.translate(this.ml, 127610, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"110px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>
</KCol>
</KRow>

<MixedChart id="698158" options={this.state.buguneKadarGunler} dataxaxisesfield="gunSayisi" options={this.state.dataSource_698158} style={{} as any}>
<MixedChartLineItem id="36359" options={this.state.BGrafikBatiAkdeniz} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(194, 172, 19, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_36359} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="427304" options={this.state.BGrafikBatimarmara} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(0, 0, 0, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_427304} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="23842" options={this.state.BGrafikDoguAkdeniz} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(19, 194, 194, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_23842} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="579088" options={this.state.BGrafikDoguKaradeniz} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(239, 111, 40, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_579088} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="944887" options={this.state.BGrafikDoguMarmara} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(24, 144, 255, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_944887} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="606586" options={this.state.BGrafikGuneyEge} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(245, 34, 45, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_606586} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="882261" options={this.state.BGrafikIcAnadolu} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(82, 196, 26, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_882261} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="350802" options={this.state.BGrafikKuzeyEge} dataxaxisvaluefield="toplamtutar" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamtutar" color="rgba(180, 0, 253, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_350802} style={{} as any}></MixedChartLineItem>
</MixedChart>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="179454" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="970742" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="825398" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderTopLeftRadius":14,"borderTopRightRadius":14,"borderBottomRightRadius":14,"borderBottomLeftRadius":14,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="962714" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="930392" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="709411" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<VerticalStack id="741324" direction="vertical" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="369964" value={ReactSystemFunctions.translate(this.ml, 369964, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(0, 0, 0, 1)"} as any}></Label>

<Label id="578745" value={ReactSystemFunctions.translate(this.ml, 578745, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(0, 0, 0, 1)","lineHeight":"20px"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="728564" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":24,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="101713" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="653719" value={ReactSystemFunctions.translate(this.ml, 653719, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":600,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>

<KCol id="402559" xs={16} sm={16} md={16} lg={16} xl={16} xxl={16} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="843585" value={ReactSystemFunctions.translate(this.ml, 843585, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":600,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="565129" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":16,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="572279" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":16,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTable id="472321" kuikaRef={this.cevdetscreen_472321_value_kuikaTableRef} form={this.props.form} dataSource={this.state.bolgeTarihliSatislar} size="middle" bordered={true} transformedOnMobileResolution={false} showHeader={true} loading={false} nodatafoundmessage={ReactSystemFunctions.translate(this.ml, 472321, "nodatafoundmessage",this.defaultML)} tableWidthMode="fit" searchable={false} sorter={false} filtering={false} pagination={false} fixedHeader={false} pageSize={10} showSizeChanger={false} columnChooser={false} striped={false} stripedColor="#F5F7FA" insertRowActive={false} insertRowPosition="top" multiSelect={false} resizableColumns={false} editable={false} globalSearch={false} showNoDataFound={true} export={false} editableAlwaysActive={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTableRow id="2044" editability={this.state.isComp2044Enabled} hoverFontColor="red" hoverBgColor="#F5F5F5" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KTableColumn id="509258" editability={this.state.isComp509258Enabled} title={ReactSystemFunctions.translate(this.ml, 509258, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KRow id="137543" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="162226" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="32710" editability={this.state.isComp32710Enabled} value="[datafield:bolge]" maxLine={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":1,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</KCol>
</KRow>
</KTableColumn>

<KTableColumn id="845553" editability={this.state.isComp845553Enabled} title={ReactSystemFunctions.translate(this.ml, 845553, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="208580" editability={this.state.isComp208580Enabled} value="[datafield:tarih1]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih1' >
<TextInput id="652748" editability={this.state.isComp652748Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih1', e?.target?.value ?? e );}} defaultValue="[datafield:tarih1]" placeholder={ReactSystemFunctions.translate(this.ml, 652748, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="929452" editability={this.state.isComp929452Enabled} title={ReactSystemFunctions.translate(this.ml, 929452, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="978552" editability={this.state.isComp978552Enabled} value="[datafield:tarih2]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih2' >
<TextInput id="453098" editability={this.state.isComp453098Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih2', e?.target?.value ?? e );}} defaultValue="[datafield:tarih2]" placeholder={ReactSystemFunctions.translate(this.ml, 453098, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="706330" editability={this.state.isComp706330Enabled} title={ReactSystemFunctions.translate(this.ml, 706330, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="31801" editability={this.state.isComp31801Enabled} value="[datafield:tarih3]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih3' >
<TextInput id="650212" editability={this.state.isComp650212Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih3', e?.target?.value ?? e );}} defaultValue="[datafield:tarih3]" placeholder={ReactSystemFunctions.translate(this.ml, 650212, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="832657" editability={this.state.isComp832657Enabled} title={ReactSystemFunctions.translate(this.ml, 832657, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="218267" editability={this.state.isComp218267Enabled} value="[datafield:tarih4]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih4' >
<TextInput id="931864" editability={this.state.isComp931864Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih4', e?.target?.value ?? e );}} defaultValue="[datafield:tarih4]" placeholder={ReactSystemFunctions.translate(this.ml, 931864, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="369022" editability={this.state.isComp369022Enabled} title={ReactSystemFunctions.translate(this.ml, 369022, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="800306" editability={this.state.isComp800306Enabled} value="[datafield:tarih5]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih5' >
<TextInput id="360619" editability={this.state.isComp360619Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih5', e?.target?.value ?? e );}} defaultValue="[datafield:tarih5]" placeholder={ReactSystemFunctions.translate(this.ml, 360619, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="180125" editability={this.state.isComp180125Enabled} title={ReactSystemFunctions.translate(this.ml, 180125, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="435599" editability={this.state.isComp435599Enabled} value="[datafield:tarih6]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih6' >
<TextInput id="760604" editability={this.state.isComp760604Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih6', e?.target?.value ?? e );}} defaultValue="[datafield:tarih6]" placeholder={ReactSystemFunctions.translate(this.ml, 760604, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="962370" editability={this.state.isComp962370Enabled} title={ReactSystemFunctions.translate(this.ml, 962370, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="415007" editability={this.state.isComp415007Enabled} value="[datafield:tarih7]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih7' >
<TextInput id="23581" editability={this.state.isComp23581Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih7', e?.target?.value ?? e );}} defaultValue="[datafield:tarih7]" placeholder={ReactSystemFunctions.translate(this.ml, 23581, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>

<KTableColumn id="519056" editability={this.state.isComp519056Enabled} title={ReactSystemFunctions.translate(this.ml, 519056, "title",this.defaultML)} isEditableColumn={true} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"60px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="559702" editability={this.state.isComp559702Enabled} value="[datafield:tarih8]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='Table_472321_tarih8' >
<TextInput id="692168" editability={this.state.isComp692168Enabled} onChange={(e: any) => {this.props.form.setFieldValue('Table_472321_tarih8', e?.target?.value ?? e );}} defaultValue="[datafield:tarih8]" placeholder={ReactSystemFunctions.translate(this.ml, 692168, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":700,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></TextInput>
</Form.Item>
</KTableColumn>
</KTableRow>
</KTable>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</div>

                    
                </Spin>
              </MainFrame_Screen>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(CevdetScreen_Screen))))));
export { tmp as CevdetScreen_Screen };
//export default tmp;
//export { tmp as CevdetScreen_Screen };

