import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMagazaUrunTavsiyeKutuTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMagazaUrunTavsiyeKutuTanim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	UrunSatisGruplariSelect: any[];
	UrunSatisGruplariSelect_dummy: any[];
	TavsiyeKutuAdetiById: any[];
	TavsiyeKutuAdetiById_dummy: any[];
	TavsiyeKutuAdetiAyniMiKontrolu: any[];
	TavsiyeKutuAdetiAyniMiKontrolu_dummy: any[];
	Notify: boolean;
	Notify_dummy: boolean;
	TavsiyeKutuAdetiSave: number;
	TavsiyeKutuAdetiSave_dummy: number;
	GoBack: any;
	GoBack_dummy: any;


}

export class MagazaUrunTavsiyeKutuTanim_ScreenBase extends React.PureComponent<IMagazaUrunTavsiyeKutuTanim_ScreenProps, any> {
	magazauruntavsiyekututanim_754460_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"e4f6ab3d-8253-479d-b8dd-9cdfbdc842c5","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":555810,"PropertyName":"value","Value":"Mağaza Ürün Tavsiye Edilen Kutu Ekleme"},{"Id":422600,"PropertyName":"value","Value":"Ürün Satış Grubu"},{"Id":754460,"PropertyName":"placeholder","Value":"Ürün Seçiniz..."},{"Id":81865,"PropertyName":"value","Value":"Adet"},{"Id":858824,"PropertyName":"placeholder","Value":"Miktar Giriniz..."},{"Id":337750,"PropertyName":"label","Value":"Kaydet"},{"Id":380048,"PropertyName":"label","Value":"İPTAL"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.magazauruntavsiyekututanim_754460_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        UrunSatisGruplariSelect: [],
	        TavsiyeKutuAdetiById: [],
	        TavsiyeKutuAdetiAyniMiKontrolu: [],
	        Notify: false,
	        TavsiyeKutuAdetiSave: 0,
	        GoBack: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("magazauruntavsiyekututanim", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.MagazaUrunTavsiyeKutuTanimPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("magazauruntavsiyekututanim", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("magazauruntavsiyekututanim", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MagazaUrunTavsiyeKutuTanimPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
magazauruntavsiyekututanim_754460_value: this.state.TavsiyeKutuAdetiById?.at?.(0)?.urunSatisGrupId ?? undefined,
magazauruntavsiyekututanim_858824_value: this.state.TavsiyeKutuAdetiById?.at?.(0)?.tavsiyeEdilenKutuAdeti ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    MagazaUrunTavsiyeKutuTanimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TavsiyeKutuAdetiId ?? this.props.screenInputs.tavsiyekutuadetiid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunTavsiyeKutuTanim/MagazaUrunTavsiyeKutuTanimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.UrunSatisGruplariSelect = result?.data.urunSatisGruplariSelect;
		
		formVars.magazauruntavsiyekututanim_754460_value = stateVars.TavsiyeKutuAdetiById?.length > 0 ? stateVars.TavsiyeKutuAdetiById[0]?.urunSatisGrupId : null;
		formVars.magazauruntavsiyekututanim_754460_options = stateVars.UrunSatisGruplariSelect;
		stateVars.TavsiyeKutuAdetiById = result?.data.tavsiyeKutuAdetiById;
		formVars.magazauruntavsiyekututanim_754460_value = stateVars.TavsiyeKutuAdetiById?.length > 0 ? stateVars.TavsiyeKutuAdetiById[0]?.urunSatisGrupId : null;
		formVars.magazauruntavsiyekututanim_754460_options = stateVars.UrunSatisGruplariSelect;
		formVars.magazauruntavsiyekututanim_858824_value = ReactSystemFunctions.value(this, stateVars.TavsiyeKutuAdetiById?.length > 0 ? stateVars.TavsiyeKutuAdetiById[0]?.tavsiyeEdilenKutuAdeti : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MagazaUrunTavsiyeKutuTanimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.magazauruntavsiyekututanim_754460_value = ReactSystemFunctions.toString(this, this.state.TavsiyeKutuAdetiById?.length > 0 ? this.state.TavsiyeKutuAdetiById[0]?.urunSatisGrupId : null);

			
			stateVars.dataSource_754460 = this.state.UrunSatisGruplariSelect;
			stateVars.dataSource_754460 = this.state.UrunSatisGruplariSelect;
			formVars.magazauruntavsiyekututanim_858824_value = ReactSystemFunctions.value(this, this.state.TavsiyeKutuAdetiById?.length > 0 ? this.state.TavsiyeKutuAdetiById[0]?.tavsiyeEdilenKutuAdeti : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    MagazaUrunTavsiyeKutuTanimComponent_337750_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "magazauruntavsiyekututanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "")), null),
				message: "*",
				formName: "magazauruntavsiyekututanim_754460_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				MagazaId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaId ?? this.props.screenInputs.magazaid, "Guid"),
				UrunSatisGrupId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazauruntavsiyekututanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "id")), "Guid"),
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TavsiyeKutuAdetiId ?? this.props.screenInputs.tavsiyekutuadetiid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunTavsiyeKutuTanim/MagazaUrunTavsiyeKutuTanimComponent_337750_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TavsiyeKutuAdetiAyniMiKontrolu = result?.data.tavsiyeKutuAdetiAyniMiKontrolu;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MagazaUrunTavsiyeKutuTanimComponent_337750_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, this.state.TavsiyeKutuAdetiAyniMiKontrolu?.length > 0 ? this.state.TavsiyeKutuAdetiAyniMiKontrolu[0]?.count : null), "0"))) {
            
				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"a69d1e03_b353_b42d_d1bf_bff43afbe2a6_notify",this.defaultML,"Aynı ürüne birden fazla tavsiye edilen kutu eklenemez." ), "danger", "bottom-right", 5, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick2_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
    MagazaUrunTavsiyeKutuTanimComponent_337750_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "magazauruntavsiyekututanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "")), null),
				message: "*",
				formName: "magazauruntavsiyekututanim_754460_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				MagazaId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaId ?? this.props.screenInputs.magazaid, "Guid"),
				TavsiyeEdilenKutuAdeti_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazauruntavsiyekututanim_858824_value", "value", "", "", "")), "number"),
				UrunSatisGrupId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazauruntavsiyekututanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "id")), "Guid"),
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TavsiyeKutuAdetiId ?? this.props.screenInputs.tavsiyekutuadetiid, "Guid")
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TavsiyeKutuAdetiAyniMiKontrolu?.length > 0 ? this.state.TavsiyeKutuAdetiAyniMiKontrolu[0]?.count : null), "0")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunTavsiyeKutuTanim/MagazaUrunTavsiyeKutuTanimComponent_337750_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TavsiyeKutuAdetiSave = result?.data.tavsiyeKutuAdetiSave;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick3_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MagazaUrunTavsiyeKutuTanimComponent_337750_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TavsiyeKutuAdetiAyniMiKontrolu?.length > 0 ? this.state.TavsiyeKutuAdetiAyniMiKontrolu[0]?.count : null), "0"))) {
            
				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }

            
            return isErrorOccurred;
        }







		MagazaUrunTavsiyeKutuTanimComponent_380048_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
