import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IUrunSatisGrupTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IUrunSatisGrupTanim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	UrunSatisGruplariById: any[];
	UrunSatisGruplariById_dummy: any[];
	UrunSatisGruplariSave: number;
	UrunSatisGruplariSave_dummy: number;
	GoBack: any;
	GoBack_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class UrunSatisGrupTanim_ScreenBase extends React.PureComponent<IUrunSatisGrupTanim_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"29573e2c-bca2-4014-8222-43508924b067","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":555810,"PropertyName":"value","Value":"Ürün Satış Grup Tanımlama Formu"},{"Id":422600,"PropertyName":"value","Value":"Ürün Satış Grup İsmi"},{"Id":195882,"PropertyName":"placeholder","Value":"İsim Yazınız..."},{"Id":868998,"PropertyName":"value","Value":"Kutudaki Adet"},{"Id":278192,"PropertyName":"placeholder","Value":"Adet Giriniz..."},{"Id":337750,"PropertyName":"label","Value":"Kaydet"},{"Id":460216,"PropertyName":"label","Value":"İPTAL"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        UrunSatisGruplariById: [],
	        UrunSatisGruplariSave: 0,
	        GoBack: "",
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("urunsatisgruptanim", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.UrunSatisGrupTanimPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("urunsatisgruptanim", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("urunsatisgruptanim", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.UrunSatisGrupTanimPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
urunsatisgruptanim_195882_value: this.state.UrunSatisGruplariById?.at?.(0)?.urunSatisGrupIsim ?? undefined,
urunsatisgruptanim_278192_value: this.state.UrunSatisGruplariById?.at?.(0)?.kutudakiAdet ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    UrunSatisGrupTanimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UrunSatisGrupId ?? this.props.screenInputs.urunsatisgrupid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupTanim/UrunSatisGrupTanimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.UrunSatisGruplariById = result?.data.urunSatisGruplariById;
		formVars.urunsatisgruptanim_195882_value = ReactSystemFunctions.toString(this, stateVars.UrunSatisGruplariById?.length > 0 ? stateVars.UrunSatisGruplariById[0]?.urunSatisGrupIsim : null);
		formVars.urunsatisgruptanim_278192_value = ReactSystemFunctions.value(this, stateVars.UrunSatisGruplariById?.length > 0 ? stateVars.UrunSatisGruplariById[0]?.kutudakiAdet : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupTanimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	UrunSatisGrupTanimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.urunsatisgruptanim_195882_value = ReactSystemFunctions.toString(this, this.state.UrunSatisGruplariById?.length > 0 ? this.state.UrunSatisGruplariById[0]?.urunSatisGrupIsim : null);

			formVars.urunsatisgruptanim_278192_value = ReactSystemFunctions.value(this, this.state.UrunSatisGruplariById?.length > 0 ? this.state.UrunSatisGruplariById[0]?.kutudakiAdet : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    UrunSatisGrupTanimComponent_337750_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "urunsatisgruptanim_195882_value", "value", "", "", "")), null)),
				message: "*",
				formName: "urunsatisgruptanim_195882_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				UrunSatisGrupIsim_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "urunsatisgruptanim_195882_value", "value", "", "", "")), "string"),
				KutudakiAdet_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "urunsatisgruptanim_278192_value", "value", "", "", "")), "number"),
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UrunSatisGrupId ?? this.props.screenInputs.urunsatisgrupid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "UrunSatisGrupTanim/UrunSatisGrupTanimComponent_337750_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.UrunSatisGruplariSave = result?.data.urunSatisGruplariSave;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.UrunSatisGrupTanimComponent_337750_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		UrunSatisGrupTanimComponent_337750_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		UrunSatisGrupTanimComponent_460216_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "UrunSatisGrupTanim", "UrunSatisGrupListe", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.UrunSatisGrupTanimPageInit();
		}

    }
}
