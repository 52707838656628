import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMagazalarTablosu_View_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMagazalarTablosu_View_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	SelectmagazalarTablosu: any[];
	SelectmagazalarTablosu_dummy: any[];


}

export class MagazalarTablosu_View_ScreenBase extends React.PureComponent<IMagazalarTablosu_View_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"5fff7bda-1463-40da-afe4-a3e801c97ff4","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"MAĞAZA"},{"Id":8035243,"PropertyName":"value","Value":"adres"},{"Id":9929611,"PropertyName":"value","Value":"bolge"},{"Id":2198579,"PropertyName":"value","Value":"depo"},{"Id":7479128,"PropertyName":"value","Value":"formatAdi"},{"Id":6371879,"PropertyName":"value","Value":"kasaSayisi"},{"Id":1222877,"PropertyName":"value","Value":"magazaAcilisTarihi"},{"Id":7113726,"PropertyName":"value","Value":"magazaAdi"},{"Id":8517449,"PropertyName":"value","Value":"magazaKodu"},{"Id":8546355,"PropertyName":"value","Value":"magazaPersonelBilgi"},{"Id":4294991,"PropertyName":"value","Value":"muhasebeKodu"},{"Id":9610775,"PropertyName":"value","Value":"sahaSorumlusu"},{"Id":3937386,"PropertyName":"value","Value":"sahaSorumlusuMail"},{"Id":5053221,"PropertyName":"value","Value":"satisAlani"},{"Id":7660184,"PropertyName":"value","Value":"sehir"},{"Id":9198328,"PropertyName":"value","Value":"sirket"},{"Id":9179422,"PropertyName":"value","Value":"standDurumu"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        SelectmagazalarTablosu: [],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("magazalartablosu_view", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.magazalarTablosu_ViewPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("magazalartablosu_view", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("magazalartablosu_view", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.magazalarTablosu_ViewPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    magazalarTablosu_ViewPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "magazalarTablosu_View/magazalarTablosu_ViewPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SelectmagazalarTablosu = result?.data.selectmagazalarTablosu;
		formVars.magazalartablosu_view_4382920_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.adres : null);
		formVars.magazalartablosu_view_8970595_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.bolge : null);
		formVars.magazalartablosu_view_9606342_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.depo : null);
		formVars.magazalartablosu_view_3775648_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.formatAdi : null);
		formVars.magazalartablosu_view_7847186_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.kasaSayisi : null);
		formVars.magazalartablosu_view_1737357_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.magazaAcilisTarihi : null);
		formVars.magazalartablosu_view_6478268_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.magazaAdi : null);
		formVars.magazalartablosu_view_8319465_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.magazaKodu : null);
		formVars.magazalartablosu_view_6294640_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.magazaPersonelBilgi : null);
		formVars.magazalartablosu_view_7709982_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.muhasebeKodu : null);
		formVars.magazalartablosu_view_8152378_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.sahaSorumlusu : null);
		formVars.magazalartablosu_view_8344834_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.sahaSorumlusuMail : null);
		formVars.magazalartablosu_view_8531373_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.satisAlani : null);
		formVars.magazalartablosu_view_7680093_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.sehir : null);
		formVars.magazalartablosu_view_7036305_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.sirket : null);
		formVars.magazalartablosu_view_7946268_value = ReactSystemFunctions.toString(this, stateVars.SelectmagazalarTablosu?.length > 0 ? stateVars.SelectmagazalarTablosu[0]?.standDurumu : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.magazalarTablosu_ViewPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	magazalarTablosu_ViewPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.magazalartablosu_view_4382920_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.adres : null);

			formVars.magazalartablosu_view_8970595_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.bolge : null);

			formVars.magazalartablosu_view_9606342_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.depo : null);

			formVars.magazalartablosu_view_3775648_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.formatAdi : null);

			formVars.magazalartablosu_view_7847186_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.kasaSayisi : null);

			formVars.magazalartablosu_view_1737357_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.magazaAcilisTarihi : null);

			formVars.magazalartablosu_view_6478268_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.magazaAdi : null);

			formVars.magazalartablosu_view_8319465_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.magazaKodu : null);

			formVars.magazalartablosu_view_6294640_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.magazaPersonelBilgi : null);

			formVars.magazalartablosu_view_7709982_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.muhasebeKodu : null);

			formVars.magazalartablosu_view_8152378_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.sahaSorumlusu : null);

			formVars.magazalartablosu_view_8344834_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.sahaSorumlusuMail : null);

			formVars.magazalartablosu_view_8531373_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.satisAlani : null);

			formVars.magazalartablosu_view_7680093_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.sehir : null);

			formVars.magazalartablosu_view_7036305_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.sirket : null);

			formVars.magazalartablosu_view_7946268_value = ReactSystemFunctions.toString(this, this.state.SelectmagazalarTablosu?.length > 0 ? this.state.SelectmagazalarTablosu[0]?.standDurumu : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		magazalarTablosu_ViewComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
