import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IKullaniciTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IKullaniciTanim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	KuserSelectById: any[];
	KuserSelectById_dummy: any[];
	KullaniciSahaPersoneliMi: any[];
	KullaniciSahaPersoneliMi_dummy: any[];
	KullaniciAdminMi: any[];
	KullaniciAdminMi_dummy: any[];
	KullaniciManagerMi: any[];
	KullaniciManagerMi_dummy: any[];
	CreateUserWithPassword: string;
	CreateUserWithPassword_dummy: string;
	AddUserToRole: boolean;
	AddUserToRole_dummy: boolean;
	RemoveUserFromRole: boolean;
	RemoveUserFromRole_dummy: boolean;
	IfThenElse: boolean;
	IfThenElse_dummy: boolean;
	GoBack: any;
	GoBack_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	isCompkullanicitanim_652581AuthorizationVisible: 'visible' | 'hidden';


}

export class KullaniciTanim_ScreenBase extends React.PureComponent<IKullaniciTanim_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"5fdbed1a-9250-48b0-9cc4-3b3a4405f25d","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":555810,"PropertyName":"value","Value":"Kullanıcı Tanımlama Formu"},{"Id":422600,"PropertyName":"value","Value":"E-posta Adresi :"},{"Id":195882,"PropertyName":"placeholder","Value":"e-posta..."},{"Id":763471,"PropertyName":"value","Value":"İsim :"},{"Id":521396,"PropertyName":"placeholder","Value":"isim..."},{"Id":511121,"PropertyName":"value","Value":"Soyisim"},{"Id":334610,"PropertyName":"placeholder","Value":"soyisim..."},{"Id":588059,"PropertyName":"value","Value":"Saha Personeli"},{"Id":389624,"PropertyName":"value","Value":"Admin"},{"Id":536055,"PropertyName":"value","Value":"Manager"},{"Id":337750,"PropertyName":"label","Value":"Kaydet"},{"Id":652581,"PropertyName":"label","Value":"İPTAL"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        KuserSelectById: [],
	        KullaniciSahaPersoneliMi: [],
	        KullaniciAdminMi: [],
	        KullaniciManagerMi: [],
	        CreateUserWithPassword: "",
	        IfThenElse: false,
	        GoBack: "",
	        NAVIGATE: "",
	        isCompkullanicitanim_652581AuthorizationVisible: 'visible',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("kullanicitanim", "");
            return;
        }
        
        
        if (true){
            await this.KullaniciTanimPageInit();
        }
        ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullanicitanim_652581AuthorizationVisible", []);

        
        
        KuikaAppManager.calculateAndSetBodyHeight("kullanicitanim", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("kullanicitanim", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.KullaniciTanimPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
kullanicitanim_195882_value: this.state.KuserSelectById?.at?.(0)?.userName ?? undefined,
kullanicitanim_521396_value: this.state.KuserSelectById?.at?.(0)?.firstName ?? undefined,
kullanicitanim_334610_value: this.state.KuserSelectById?.at?.(0)?.lastName ?? undefined,
kullanicitanim_983945_value: this.state.KullaniciSahaPersoneliMi?.at?.(0)?.value ?? undefined,
kullanicitanim_238863_value: this.state.KullaniciAdminMi?.at?.(0)?.value ?? undefined,
kullanicitanim_723134_value: this.state.KullaniciManagerMi?.at?.(0)?.value ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    KullaniciTanimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UserId ?? this.props.screenInputs.userid, "Guid"),
			KUser_Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UserId ?? this.props.screenInputs.userid, "Guid"),
			KUser_Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UserId ?? this.props.screenInputs.userid, "Guid"),
			KUser_Id_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.UserId ?? this.props.screenInputs.userid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "KullaniciTanim/KullaniciTanimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.KuserSelectById = result?.data.kuserSelectById;
		formVars.kullanicitanim_195882_value = ReactSystemFunctions.toString(this, stateVars.KuserSelectById?.length > 0 ? stateVars.KuserSelectById[0]?.userName : null);
		formVars.kullanicitanim_521396_value = ReactSystemFunctions.toString(this, stateVars.KuserSelectById?.length > 0 ? stateVars.KuserSelectById[0]?.firstName : null);
		formVars.kullanicitanim_334610_value = ReactSystemFunctions.toString(this, stateVars.KuserSelectById?.length > 0 ? stateVars.KuserSelectById[0]?.lastName : null);
		stateVars.KullaniciSahaPersoneliMi = result?.data.kullaniciSahaPersoneliMi;
		formVars.kullanicitanim_983945_value = ReactSystemFunctions.value(this, stateVars.KullaniciSahaPersoneliMi?.length > 0 ? stateVars.KullaniciSahaPersoneliMi[0]?.value : null);
		stateVars.KullaniciAdminMi = result?.data.kullaniciAdminMi;
		formVars.kullanicitanim_238863_value = ReactSystemFunctions.value(this, stateVars.KullaniciAdminMi?.length > 0 ? stateVars.KullaniciAdminMi[0]?.value : null);
		stateVars.KullaniciManagerMi = result?.data.kullaniciManagerMi;
		formVars.kullanicitanim_723134_value = ReactSystemFunctions.value(this, stateVars.KullaniciManagerMi?.length > 0 ? stateVars.KullaniciManagerMi[0]?.value : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciTanimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	KullaniciTanimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.kullanicitanim_195882_value = ReactSystemFunctions.toString(this, this.state.KuserSelectById?.length > 0 ? this.state.KuserSelectById[0]?.userName : null);

			formVars.kullanicitanim_521396_value = ReactSystemFunctions.toString(this, this.state.KuserSelectById?.length > 0 ? this.state.KuserSelectById[0]?.firstName : null);

			formVars.kullanicitanim_334610_value = ReactSystemFunctions.toString(this, this.state.KuserSelectById?.length > 0 ? this.state.KuserSelectById[0]?.lastName : null);

			formVars.kullanicitanim_983945_value = ReactSystemFunctions.value(this, this.state.KullaniciSahaPersoneliMi?.length > 0 ? this.state.KullaniciSahaPersoneliMi[0]?.value : null);

			formVars.kullanicitanim_238863_value = ReactSystemFunctions.value(this, this.state.KullaniciAdminMi?.length > 0 ? this.state.KullaniciAdminMi[0]?.value : null);

			formVars.kullanicitanim_723134_value = ReactSystemFunctions.value(this, this.state.KullaniciManagerMi?.length > 0 ? this.state.KullaniciManagerMi[0]?.value : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		KullaniciTanimComponent_337750_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_521396_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null),
			message: "*",
			formName: "kullanicitanim_334610_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, this.state.KuserSelectById?.length > 0 ? this.state.KuserSelectById[0]?.id : null), null))) {
            KuikaAppManager.showSpinner(this);

				stateVars.CreateUserWithPassword = await ReactSystemFunctions.createUserWithPassword(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_521396_value", "value", "", "", "")), ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_334610_value", "value", "", "", "")), "", "", "");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick1_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		KullaniciTanimComponent_337750_onClick_if1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole("Saha Personeli", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick_else1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.RemoveUserFromRole = await ReactSystemFunctions.removeUserFromRole("Saha Personeli", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kullanicitanim_983945_value", "value", "", "", "")), true))) {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_if1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_else1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
		KullaniciTanimComponent_337750_onClick_if2 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole("Admin", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick_else2 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.RemoveUserFromRole = await ReactSystemFunctions.removeUserFromRole("Admin", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kullanicitanim_238863_value", "value", "", "", "")), true))) {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_if2();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_else2();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick3_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
		KullaniciTanimComponent_337750_onClick_if3 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole("Manager", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick_else3 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")), null)),
			message: "*",
			formName: "kullanicitanim_195882_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.RemoveUserFromRole = await ReactSystemFunctions.removeUserFromRole("Manager", ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kullanicitanim_195882_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		KullaniciTanimComponent_337750_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kullanicitanim_723134_value", "value", "", "", "")), true))) {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_if3();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.KullaniciTanimComponent_337750_onClick_else3();
                if (isErrorOccurred) return true;
        }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);
					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "KullaniciTanim", "KullaniciListe", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		KullaniciTanimComponent_652581_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.KullaniciTanimPageInit();
		}

    }
}
