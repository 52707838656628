import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IBolgeTRSatis_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IBolgeTRSatis_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	buguneKadarGunler: any[];
	buguneKadarGunler_dummy: any[];
	BGrafikBatiAkdeniz: any[];
	BGrafikBatiAkdeniz_dummy: any[];
	BGrafikBatimarmara: any[];
	BGrafikBatimarmara_dummy: any[];
	BGrafikDoguAkdeniz: any[];
	BGrafikDoguAkdeniz_dummy: any[];
	BGrafikDoguKaradeniz: any[];
	BGrafikDoguKaradeniz_dummy: any[];
	BGrafikDoguMarmara: any[];
	BGrafikDoguMarmara_dummy: any[];
	BGrafikGuneyEge: any[];
	BGrafikGuneyEge_dummy: any[];
	BGrafikIcAnadolu: any[];
	BGrafikIcAnadolu_dummy: any[];
	BGrafikKuzeyEge: any[];
	BGrafikKuzeyEge_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class BolgeTRSatis_ScreenBase extends React.PureComponent<IBolgeTRSatis_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"79f3a388-0265-400e-8cc7-9dd6a47f0426","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":686479,"PropertyName":"value","Value":"KUZEY EGE"},{"Id":275941,"PropertyName":"value","Value":"DOĞU MARMARA"},{"Id":97110,"PropertyName":"value","Value":"GÜNEY EGE"},{"Id":770680,"PropertyName":"value","Value":"DOĞU AKDENİZ/GÜNEYDOĞU"},{"Id":192615,"PropertyName":"value","Value":"DOĞU KARADENİZ /KUZEYDOĞU"},{"Id":948632,"PropertyName":"value","Value":"BATI MARMARA"},{"Id":125699,"PropertyName":"value","Value":"İÇ ANADOLU"},{"Id":87433,"PropertyName":"value","Value":"BATI AKDENİZ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        buguneKadarGunler: [],
	        BGrafikBatiAkdeniz: [],
	        BGrafikBatimarmara: [],
	        BGrafikDoguAkdeniz: [],
	        BGrafikDoguKaradeniz: [],
	        BGrafikDoguMarmara: [],
	        BGrafikGuneyEge: [],
	        BGrafikIcAnadolu: [],
	        BGrafikKuzeyEge: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("bolgetrsatis", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.bolgeTRSatisPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("bolgetrsatis", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("bolgetrsatis", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.bolgeTRSatisPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.bolgeTRSatisPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    bolgeTRSatisPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "bolgeTRSatis/bolgeTRSatisPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.buguneKadarGunler = result?.data.buguneKadarGunler;
		
		stateVars.BGrafikBatiAkdeniz = result?.data.bGrafikBatiAkdeniz;
		stateVars.BGrafikBatimarmara = result?.data.bGrafikBatimarmara;
		stateVars.BGrafikDoguAkdeniz = result?.data.bGrafikDoguAkdeniz;
		stateVars.BGrafikDoguKaradeniz = result?.data.bGrafikDoguKaradeniz;
		stateVars.BGrafikDoguMarmara = result?.data.bGrafikDoguMarmara;
		stateVars.BGrafikGuneyEge = result?.data.bGrafikGuneyEge;
		stateVars.BGrafikIcAnadolu = result?.data.bGrafikIcAnadolu;
		stateVars.BGrafikKuzeyEge = result?.data.bGrafikKuzeyEge;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.bolgeTRSatisPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	bolgeTRSatisPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_634046 = this.state.buguneKadarGunler;
			stateVars.dataSource_634046 = this.state.buguneKadarGunler;
			
			stateVars.dataSource_500877 = this.state.BGrafikBatiAkdeniz;
			stateVars.dataSource_500877 = this.state.BGrafikBatiAkdeniz;
			
			stateVars.dataSource_942108 = this.state.BGrafikBatimarmara;
			stateVars.dataSource_942108 = this.state.BGrafikBatimarmara;
			
			stateVars.dataSource_257635 = this.state.BGrafikDoguAkdeniz;
			stateVars.dataSource_257635 = this.state.BGrafikDoguAkdeniz;
			
			stateVars.dataSource_29760 = this.state.BGrafikDoguKaradeniz;
			stateVars.dataSource_29760 = this.state.BGrafikDoguKaradeniz;
			
			stateVars.dataSource_743354 = this.state.BGrafikDoguMarmara;
			stateVars.dataSource_743354 = this.state.BGrafikDoguMarmara;
			
			stateVars.dataSource_657791 = this.state.BGrafikGuneyEge;
			stateVars.dataSource_657791 = this.state.BGrafikGuneyEge;
			
			stateVars.dataSource_418862 = this.state.BGrafikIcAnadolu;
			stateVars.dataSource_418862 = this.state.BGrafikIcAnadolu;
			
			stateVars.dataSource_968729 = this.state.BGrafikKuzeyEge;
			stateVars.dataSource_968729 = this.state.BGrafikKuzeyEge;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
