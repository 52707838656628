import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISahaPersSatisDagilim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISahaPersSatisDagilim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	tarihuret: any[];
	tarihuret_dummy: any[];
	sorumluyaGoreMagaza: any[];
	sorumluyaGoreMagaza_dummy: any[];
	kalanchoe6Grafik: any[];
	kalanchoe6Grafik_dummy: any[];
	kalanchoe10Grafik: any[];
	kalanchoe10Grafik_dummy: any[];
	campanulaGrafik: any[];
	campanulaGrafik_dummy: any[];
	rose10Grafik: any[];
	rose10Grafik_dummy: any[];
	onepackGrafik: any[];
	onepackGrafik_dummy: any[];
	succulent5Grafik: any[];
	succulent5Grafik_dummy: any[];
	succulent8Grafik: any[];
	succulent8Grafik_dummy: any[];
	poinsettiaGrafik: any[];
	poinsettiaGrafik_dummy: any[];
	euphorbia10Grafik: any[];
	euphorbia10Grafik_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class SahaPersSatisDagilim_ScreenBase extends React.PureComponent<ISahaPersSatisDagilim_ScreenProps, any> {
	sahaperssatisdagilim_815630_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"f5d90e85-43fb-4c4f-9cbe-7c9314c7fb3b","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":815630,"PropertyName":"placeholder","Value":"Lütfen mağaza seçiniz..."},{"Id":764982,"PropertyName":"value","Value":"KALANCHOE Q6"},{"Id":165615,"PropertyName":"value","Value":"ROSE Q10,5"},{"Id":26246,"PropertyName":"value","Value":"EUPHORBIA Q10,5"},{"Id":95066,"PropertyName":"value","Value":"POINSETTIA Q10,5"},{"Id":1238,"PropertyName":"value","Value":"CAMPANULA Q10.5"},{"Id":463749,"PropertyName":"value","Value":"SUCCULENT Q5,5 "},{"Id":427392,"PropertyName":"value","Value":"KALANCHOE Q10,5"},{"Id":565703,"PropertyName":"value","Value":"SUCCULENT Q8,5"},{"Id":816612,"PropertyName":"value","Value":"CREATION 1PACK"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.sahaperssatisdagilim_815630_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        tarihuret: [],
	        sorumluyaGoreMagaza: [],
	        kalanchoe6Grafik: [],
	        kalanchoe10Grafik: [],
	        campanulaGrafik: [],
	        rose10Grafik: [],
	        onepackGrafik: [],
	        succulent5Grafik: [],
	        succulent8Grafik: [],
	        poinsettiaGrafik: [],
	        euphorbia10Grafik: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("sahaperssatisdagilim", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.sahaPersSatisDagilimPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("sahaperssatisdagilim", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("sahaperssatisdagilim", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.sahaPersSatisDagilimPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.sahaPersSatisDagilimPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    sahaPersSatisDagilimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "sahaPersSatisDagilim/sahaPersSatisDagilimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.tarihuret = result?.data.tarihuret;
		
		formVars.sahaperssatisdagilim_227062_value = ReactSystemFunctions.toString(this, stateVars.tarihuret?.length > 0 ? stateVars.tarihuret[0]?.magazaAdi : null);
		stateVars.sorumluyaGoreMagaza = result?.data.sorumluyaGoreMagaza;
		
		formVars.sahaperssatisdagilim_815630_options = stateVars.sorumluyaGoreMagaza;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.sahaPersSatisDagilimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	sahaPersSatisDagilimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.sahaperssatisdagilim_227062_value = ReactSystemFunctions.toString(this, this.state.tarihuret?.length > 0 ? this.state.tarihuret[0]?.magazaAdi : null);

			
			stateVars.dataSource_815630 = this.state.sorumluyaGoreMagaza;
			stateVars.dataSource_815630 = this.state.sorumluyaGoreMagaza;
			
			stateVars.dataSource_159130 = this.state.tarihuret;
			stateVars.dataSource_159130 = this.state.tarihuret;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    sahaPersSatisDagilimComponent_815630_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_7: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_8: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid"),
				Id_9: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahaperssatisdagilim_815630_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "sahaPersSatisDagilim/sahaPersSatisDagilimComponent_815630_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.tarihuret = result?.data.tarihuret;
			
			formVars.sahaperssatisdagilim_227062_value = ReactSystemFunctions.toString(this, stateVars.tarihuret?.length > 0 ? stateVars.tarihuret[0]?.magazaAdi : null);
			stateVars.kalanchoe6Grafik = result?.data.kalanchoe6Grafik;
			stateVars.kalanchoe10Grafik = result?.data.kalanchoe10Grafik;
			stateVars.campanulaGrafik = result?.data.campanulaGrafik;
			stateVars.rose10Grafik = result?.data.rose10Grafik;
			stateVars.onepackGrafik = result?.data.onepackGrafik;
			stateVars.succulent5Grafik = result?.data.succulent5Grafik;
			stateVars.succulent8Grafik = result?.data.succulent8Grafik;
			stateVars.poinsettiaGrafik = result?.data.poinsettiaGrafik;
			stateVars.euphorbia10Grafik = result?.data.euphorbia10Grafik;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.sahaPersSatisDagilimComponent_815630_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		sahaPersSatisDagilimComponent_815630_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.sahaperssatisdagilim_227062_value = ReactSystemFunctions.toString(this, this.state.tarihuret?.length > 0 ? this.state.tarihuret[0]?.magazaAdi : null);

				
				stateVars.dataSource_159130 = this.state.tarihuret;
				stateVars.dataSource_159130 = this.state.tarihuret;
				
				stateVars.dataSource_71693 = this.state.kalanchoe6Grafik;
				stateVars.dataSource_71693 = this.state.kalanchoe6Grafik;
				
				stateVars.dataSource_517529 = this.state.kalanchoe10Grafik;
				stateVars.dataSource_517529 = this.state.kalanchoe10Grafik;
				
				stateVars.dataSource_771638 = this.state.euphorbia10Grafik;
				stateVars.dataSource_771638 = this.state.euphorbia10Grafik;
				
				stateVars.dataSource_172266 = this.state.rose10Grafik;
				stateVars.dataSource_172266 = this.state.rose10Grafik;
				
				stateVars.dataSource_84552 = this.state.poinsettiaGrafik;
				stateVars.dataSource_84552 = this.state.poinsettiaGrafik;
				
				stateVars.dataSource_837896 = this.state.succulent5Grafik;
				stateVars.dataSource_837896 = this.state.succulent5Grafik;
				
				stateVars.dataSource_130146 = this.state.succulent8Grafik;
				stateVars.dataSource_130146 = this.state.succulent8Grafik;
				
				stateVars.dataSource_773199 = this.state.onepackGrafik;
				stateVars.dataSource_773199 = this.state.onepackGrafik;
				
				stateVars.dataSource_397830 = this.state.campanulaGrafik;
				stateVars.dataSource_397830 = this.state.campanulaGrafik;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
