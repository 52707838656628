import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISignin_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISignin_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	KuserSelectAllUser: any[];
	KuserSelectAllUser_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	Signin: any;
	Signin_dummy: any;
	goToNextScreenAfterLogin: any;
	goToNextScreenAfterLogin_dummy: any;


}

export class Signin_ScreenBase extends React.PureComponent<ISignin_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"2c582728-971f-4fce-95d4-09be5562b18c","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":577731,"PropertyName":"value","Value":"Şu anda giriş yapamıyorsanız,  lütfen yardım için admine başvurun."},{"Id":675608,"PropertyName":"value","Value":"E-POSTA"},{"Id":828573,"PropertyName":"placeholder","Value":"E-posta"},{"Id":110793,"PropertyName":"value","Value":"ŞİFRE"},{"Id":452791,"PropertyName":"placeholder","Value":"ŞİFRE"},{"Id":385525,"PropertyName":"value","Value":"Yeni şifre belirle"},{"Id":641651,"PropertyName":"label","Value":"Giriş Yap"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        KuserSelectAllUser: [],
	        NAVIGATE: "",
	        Signin: "",
	        goToNextScreenAfterLogin: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("signin", "");
            return;
        }
        
        
        if (true){
            await this.SigninPageInit();
        }
        
        window.addEventListener("keydown", this.onKeyDown)
        
        KuikaAppManager.calculateAndSetBodyHeight("signin", "");
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        
        
    }

    

        

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if(keyCode === 13){
      var element = document.getElementById("641651");
      if (element && element.click)
            {
                element.click();
            }
        }
    }

    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("signin", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.SigninPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    SigninPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Signin/SigninPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.KuserSelectAllUser = result?.data.kuserSelectAllUser;
		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		SigninComponent_385525_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Signin", "Authentication_Forgot_Password", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		SigninComponent_641651_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "signin_828573_value", "value", "", "", "")), null)),
			message: "This field is required",
			formName: "signin_828573_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isValidEmail(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "signin_828573_value", "value", "", "", "")), null),
			message: "Geçerli bir mail adresi giriniz.",
			formName: "signin_828573_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "signin_452791_value", "value", "", "", "")), null),
			message: "This field is required",
			formName: "signin_452791_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.Signin = (await ReactSystemFunctions.signin(this, ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signin_828573_value", "value", "", "", "")), ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signin_452791_value", "value", "", "", ""))) as any).data;
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterLogin(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.SigninPageInit();
		}

    }
}
