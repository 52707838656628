import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IVerificationCode_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IVerificationCode_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	IsVerificationCodeValid: boolean;
	IsVerificationCodeValid_dummy: boolean;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class VerificationCode_ScreenBase extends React.PureComponent<IVerificationCode_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"3ea92839-3847-46b8-a5ef-e2fb73affab8","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":695885,"PropertyName":"value","Value":"E-postanızı Doğrulayın"},{"Id":722774,"PropertyName":"value","Value":"Lütfen doğrulama kodunuzu girin"},{"Id":846142,"PropertyName":"value","Value":"E-posta adresinize gönderilen doğrulama kodunu girin."},{"Id":138256,"PropertyName":"placeholder","Value":"Doğrulama kodunu buraya girin"},{"Id":854781,"PropertyName":"label","Value":"E-postayı Doğrula"},{"Id":384658,"PropertyName":"value","Value":"Geri dön"},{"Id":28660,"PropertyName":"value","Value":"Giriş yap"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        IsVerificationCodeValid: false,
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("verificationcode", "");
            return;
        }
        
        
        if (true){
            
        }
        
        window.addEventListener("keydown", this.onKeyDown)
        
        KuikaAppManager.calculateAndSetBodyHeight("verificationcode", "");
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        
        
    }

    

        

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if(keyCode === 13){
      var element = document.getElementById("854781");
      if (element && element.click)
            {
                element.click();
            }
        }
    }

    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("verificationcode", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
verificationcode_138256_value: undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      


		VerificationCodeComponent_854781_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "verificationcode_138256_value", "value", "", "", "")), null),
			message: "This field is required",
			formName: "verificationcode_138256_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.IsVerificationCodeValid = await ReactSystemFunctions.isVerificationCodeValid(this,ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "verificationcode_138256_value", "value", "", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		VerificationCodeComponent_28660_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "VerificationCode", "Signin", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];

    }
}
