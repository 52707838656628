import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMagazaUrunSatisTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMagazaUrunSatisTanim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	UrunSatisGruplariSelect: any[];
	UrunSatisGruplariSelect_dummy: any[];
	MagazaUrunSatisById: any[];
	MagazaUrunSatisById_dummy: any[];
	MagazaUrunSatisTarihKontrolu: any[];
	MagazaUrunSatisTarihKontrolu_dummy: any[];
	Notify: boolean;
	Notify_dummy: boolean;
	GoBack: any;
	GoBack_dummy: any;
	MagazaUrunSatisSave: number;
	MagazaUrunSatisSave_dummy: number;


}

export class MagazaUrunSatisTanim_ScreenBase extends React.PureComponent<IMagazaUrunSatisTanim_ScreenProps, any> {
	magazaurunsatistanim_754460_value_kuikaSelectBoxRef: React.RefObject<any>;
	magazaurunsatistanim_160958_value_kuikaDateRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"30d152ca-013d-4712-a4d3-66232a44d3a8","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":555810,"PropertyName":"value","Value":"Mağaza Ürün Satış Ekleme"},{"Id":422600,"PropertyName":"value","Value":"Ürün Satış Grubu"},{"Id":754460,"PropertyName":"placeholder","Value":"Ürün Seçiniz..."},{"Id":596523,"PropertyName":"value","Value":"Tarih"},{"Id":160958,"PropertyName":"placeholder","Value":"Tarih Seçiniz..."},{"Id":81865,"PropertyName":"value","Value":"Miktar"},{"Id":858824,"PropertyName":"placeholder","Value":"Miktar Giriniz..."},{"Id":337750,"PropertyName":"label","Value":"Kaydet"},{"Id":562723,"PropertyName":"label","Value":"İPTAL"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.magazaurunsatistanim_754460_value_kuikaSelectBoxRef = React.createRef();
		this.magazaurunsatistanim_160958_value_kuikaDateRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        UrunSatisGruplariSelect: [],
	        MagazaUrunSatisById: [],
	        MagazaUrunSatisTarihKontrolu: [],
	        Notify: false,
	        GoBack: "",
	        MagazaUrunSatisSave: 0,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("magazaurunsatistanim", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.MagazaUrunSatisTanimPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("magazaurunsatistanim", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("magazaurunsatistanim", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MagazaUrunSatisTanimPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
magazaurunsatistanim_754460_value: this.state.MagazaUrunSatisById?.at?.(0)?.urunSatisGrupId ?? undefined,
magazaurunsatistanim_160958_value: this.state.MagazaUrunSatisById?.at?.(0)?.tarih ?? undefined,
magazaurunsatistanim_858824_value: this.state.MagazaUrunSatisById?.at?.(0)?.miktar ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    MagazaUrunSatisTanimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaUrunSatisId ?? this.props.screenInputs.magazaurunsatisid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunSatisTanim/MagazaUrunSatisTanimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.UrunSatisGruplariSelect = result?.data.urunSatisGruplariSelect;
		
		formVars.magazaurunsatistanim_754460_value = stateVars.MagazaUrunSatisById?.length > 0 ? stateVars.MagazaUrunSatisById[0]?.urunSatisGrupId : null;
		formVars.magazaurunsatistanim_754460_options = stateVars.UrunSatisGruplariSelect;
		stateVars.MagazaUrunSatisById = result?.data.magazaUrunSatisById;
		formVars.magazaurunsatistanim_754460_value = stateVars.MagazaUrunSatisById?.length > 0 ? stateVars.MagazaUrunSatisById[0]?.urunSatisGrupId : null;
		formVars.magazaurunsatistanim_754460_options = stateVars.UrunSatisGruplariSelect;
		formVars.magazaurunsatistanim_160958_value = ReactSystemFunctions.value(this, stateVars.MagazaUrunSatisById?.length > 0 ? stateVars.MagazaUrunSatisById[0]?.tarih : null);
		formVars.magazaurunsatistanim_858824_value = ReactSystemFunctions.value(this, stateVars.MagazaUrunSatisById?.length > 0 ? stateVars.MagazaUrunSatisById[0]?.miktar : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MagazaUrunSatisTanimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.magazaurunsatistanim_754460_value = ReactSystemFunctions.toString(this, this.state.MagazaUrunSatisById?.length > 0 ? this.state.MagazaUrunSatisById[0]?.urunSatisGrupId : null);

			
			stateVars.dataSource_754460 = this.state.UrunSatisGruplariSelect;
			stateVars.dataSource_754460 = this.state.UrunSatisGruplariSelect;
			formVars.magazaurunsatistanim_160958_value = ReactSystemFunctions.value(this, this.state.MagazaUrunSatisById?.length > 0 ? this.state.MagazaUrunSatisById[0]?.tarih : null);

			formVars.magazaurunsatistanim_858824_value = ReactSystemFunctions.value(this, this.state.MagazaUrunSatisById?.length > 0 ? this.state.MagazaUrunSatisById[0]?.miktar : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    MagazaUrunSatisTanimComponent_337750_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "")), null),
				message: "*",
				formName: "magazaurunsatistanim_754460_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				MagazaId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaId ?? this.props.screenInputs.magazaid, "Guid"),
				UrunSatisGrupId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "id")), "Guid"),
				Tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_160958_value", "value", "", "", "")), "Date"),
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaUrunSatisId ?? this.props.screenInputs.magazaurunsatisid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunSatisTanim/MagazaUrunSatisTanimComponent_337750_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MagazaUrunSatisTarihKontrolu = result?.data.magazaUrunSatisTarihKontrolu;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimComponent_337750_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MagazaUrunSatisTanimComponent_337750_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, this.state.MagazaUrunSatisTarihKontrolu?.length > 0 ? this.state.MagazaUrunSatisTarihKontrolu[0]?.count : null), "0"))) {
            
				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"a69d1e03_b353_b42d_d1bf_bff43afbe2a6_notify",this.defaultML,"Aynı tarihli birden fazla ürün satış grubu eklemenez." ), "danger", "bottom-right", 5, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimComponent_337750_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimComponent_337750_onClick2_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		MagazaUrunSatisTanimComponent_337750_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.MagazaUrunSatisTarihKontrolu?.length > 0 ? this.state.MagazaUrunSatisTarihKontrolu[0]?.count : null), "0"))) {
            
				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimComponent_337750_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaUrunSatisTanimComponent_337750_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
    MagazaUrunSatisTanimComponent_337750_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "")), null),
				message: "*",
				formName: "magazaurunsatistanim_754460_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				MagazaId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaId ?? this.props.screenInputs.magazaid, "Guid"),
				Miktar_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_858824_value", "value", "", "", "")), "number"),
				Tarih_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_160958_value", "value", "", "", "")), "Date"),
				UrunSatisGrupId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazaurunsatistanim_754460_value", "value", "UrunSatisGruplariSelect", "id", "id")), "Guid"),
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaUrunSatisId ?? this.props.screenInputs.magazaurunsatisid, "Guid")
        }

if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.MagazaUrunSatisTarihKontrolu?.length > 0 ? this.state.MagazaUrunSatisTarihKontrolu[0]?.count : null), "0"))) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaUrunSatisTanim/MagazaUrunSatisTanimComponent_337750_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MagazaUrunSatisSave = result?.data.magazaUrunSatisSave;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }







		MagazaUrunSatisTanimComponent_562723_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
