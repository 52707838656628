import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISahaPersMagazaInfo_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISahaPersMagazaInfo_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	KuserSelectByUserName: any[];
	KuserSelectByUserName_dummy: any[];
	sorumluyaGoreMagaza: any[];
	sorumluyaGoreMagaza_dummy: any[];
	sorumluyaKodlaMagazaGetir: any[];
	sorumluyaKodlaMagazaGetir_dummy: any[];
	magazaVeriDoldur: any[];
	magazaVeriDoldur_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class SahaPersMagazaInfo_ScreenBase extends React.PureComponent<ISahaPersMagazaInfo_ScreenProps, any> {
	sahapersmagazainfo_249497_value_kuikaSelectBoxRef: React.RefObject<any>;
	sahapersmagazainfo_323955_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"5533ec58-6bdb-49e6-9afc-a25d0b2b9dca","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":666432,"PropertyName":"value","Value":"Mağaza Bilgileri"},{"Id":249497,"PropertyName":"placeholder","Value":"Lütfen mağaza seçiniz.."},{"Id":323955,"PropertyName":"placeholder","Value":"Lütfen kod seçiniz.."},{"Id":670821,"PropertyName":"value","Value":"ŞİRKET"},{"Id":150304,"PropertyName":"value","Value":"MAĞAZA KODU"},{"Id":360783,"PropertyName":"value","Value":"STAND DURUMU"},{"Id":646021,"PropertyName":"value","Value":"SAHA SORUMLUSU"},{"Id":929024,"PropertyName":"value","Value":"ŞEHİR"},{"Id":833416,"PropertyName":"value","Value":"BÖLGE"},{"Id":621524,"PropertyName":"value","Value":"BAĞLI OLDUĞU DEPO"},{"Id":24425,"PropertyName":"value","Value":"Mağazadaki Çalışan Hakkında Bilgiler "},{"Id":781878,"PropertyName":"value","Value":"MAĞAZA AÇILIŞ TARİHİ"},{"Id":147337,"PropertyName":"value","Value":"MAĞAZA AÇIK ADRESİ"},{"Id":350518,"PropertyName":"value","Value":"MUHASEBE KODU"},{"Id":670970,"PropertyName":"value","Value":"FORMAT"},{"Id":255082,"PropertyName":"value","Value":"SATIŞ ALANI"},{"Id":564806,"PropertyName":"value","Value":"KASA SAYISI"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.sahapersmagazainfo_249497_value_kuikaSelectBoxRef = React.createRef();
		this.sahapersmagazainfo_323955_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        KuserSelectByUserName: [],
	        sorumluyaGoreMagaza: [],
	        sorumluyaKodlaMagazaGetir: [],
	        magazaVeriDoldur: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("sahapersmagazainfo", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.sahaPersMagazaInfoPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("sahapersmagazainfo", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("sahapersmagazainfo", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.sahaPersMagazaInfoPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.sahaPersMagazaInfoPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    sahaPersMagazaInfoPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "sahaPersMagazaInfo/sahaPersMagazaInfoPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.KuserSelectByUserName = result?.data.kuserSelectByUserName;
		stateVars.sorumluyaGoreMagaza = result?.data.sorumluyaGoreMagaza;
		
		formVars.sahapersmagazainfo_249497_options = stateVars.sorumluyaGoreMagaza;
		stateVars.sorumluyaKodlaMagazaGetir = result?.data.sorumluyaKodlaMagazaGetir;
		
		formVars.sahapersmagazainfo_323955_options = stateVars.sorumluyaKodlaMagazaGetir;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.sahaPersMagazaInfoPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	sahaPersMagazaInfoPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_249497 = this.state.sorumluyaGoreMagaza;
			stateVars.dataSource_249497 = this.state.sorumluyaGoreMagaza;
			
			stateVars.dataSource_323955 = this.state.sorumluyaKodlaMagazaGetir;
			stateVars.dataSource_323955 = this.state.sorumluyaKodlaMagazaGetir;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    sahaPersMagazaInfoComponent_249497_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahapersmagazainfo_249497_value", "value", "sorumluyaGoreMagaza", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "sahaPersMagazaInfo/sahaPersMagazaInfoComponent_249497_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.magazaVeriDoldur = result?.data.magazaVeriDoldur;
			formVars.sahapersmagazainfo_841788_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAdi : null);
			formVars.sahapersmagazainfo_983060_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sirket : null);
			formVars.sahapersmagazainfo_450249_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaKodu : null);
			formVars.sahapersmagazainfo_221509_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.standDurumu : null);
			formVars.sahapersmagazainfo_239365_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sahaSorumlusu : null);
			formVars.sahapersmagazainfo_856468_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sehir : null);
			formVars.sahapersmagazainfo_35793_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.bolge : null);
			formVars.sahapersmagazainfo_546241_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.depo : null);
			formVars.sahapersmagazainfo_979617_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);
			formVars.sahapersmagazainfo_813612_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);
			formVars.sahapersmagazainfo_741239_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.adres : null);
			formVars.sahapersmagazainfo_103061_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.muhasebeKodu : null);
			formVars.sahapersmagazainfo_691009_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.formatAdi : null);
			formVars.sahapersmagazainfo_773688_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.satisAlani : null);
			formVars.sahapersmagazainfo_216737_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.kasaSayisi : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.sahaPersMagazaInfoComponent_249497_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		sahaPersMagazaInfoComponent_249497_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.sahapersmagazainfo_841788_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAdi : null);

				formVars.sahapersmagazainfo_983060_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sirket : null);

				formVars.sahapersmagazainfo_450249_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaKodu : null);

				formVars.sahapersmagazainfo_221509_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.standDurumu : null);

				formVars.sahapersmagazainfo_239365_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sahaSorumlusu : null);

				formVars.sahapersmagazainfo_856468_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sehir : null);

				formVars.sahapersmagazainfo_35793_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.bolge : null);

				formVars.sahapersmagazainfo_546241_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.depo : null);

				formVars.sahapersmagazainfo_979617_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);

				formVars.sahapersmagazainfo_813612_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);

				formVars.sahapersmagazainfo_741239_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.adres : null);

				formVars.sahapersmagazainfo_103061_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.muhasebeKodu : null);

				formVars.sahapersmagazainfo_691009_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.formatAdi : null);

				formVars.sahapersmagazainfo_773688_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.satisAlani : null);

				formVars.sahapersmagazainfo_216737_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.kasaSayisi : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    sahaPersMagazaInfoComponent_323955_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sahapersmagazainfo_323955_value", "value", "sorumluyaKodlaMagazaGetir", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "sahaPersMagazaInfo/sahaPersMagazaInfoComponent_323955_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.magazaVeriDoldur = result?.data.magazaVeriDoldur;
			formVars.sahapersmagazainfo_841788_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAdi : null);
			formVars.sahapersmagazainfo_983060_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sirket : null);
			formVars.sahapersmagazainfo_450249_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaKodu : null);
			formVars.sahapersmagazainfo_221509_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.standDurumu : null);
			formVars.sahapersmagazainfo_239365_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sahaSorumlusu : null);
			formVars.sahapersmagazainfo_856468_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sehir : null);
			formVars.sahapersmagazainfo_35793_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.bolge : null);
			formVars.sahapersmagazainfo_546241_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.depo : null);
			formVars.sahapersmagazainfo_979617_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);
			formVars.sahapersmagazainfo_813612_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);
			formVars.sahapersmagazainfo_741239_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.adres : null);
			formVars.sahapersmagazainfo_103061_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.muhasebeKodu : null);
			formVars.sahapersmagazainfo_691009_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.formatAdi : null);
			formVars.sahapersmagazainfo_773688_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.satisAlani : null);
			formVars.sahapersmagazainfo_216737_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.kasaSayisi : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.sahaPersMagazaInfoComponent_323955_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		sahaPersMagazaInfoComponent_323955_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.sahapersmagazainfo_841788_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAdi : null);

				formVars.sahapersmagazainfo_983060_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sirket : null);

				formVars.sahapersmagazainfo_450249_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaKodu : null);

				formVars.sahapersmagazainfo_221509_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.standDurumu : null);

				formVars.sahapersmagazainfo_239365_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sahaSorumlusu : null);

				formVars.sahapersmagazainfo_856468_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sehir : null);

				formVars.sahapersmagazainfo_35793_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.bolge : null);

				formVars.sahapersmagazainfo_546241_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.depo : null);

				formVars.sahapersmagazainfo_979617_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);

				formVars.sahapersmagazainfo_813612_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);

				formVars.sahapersmagazainfo_741239_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.adres : null);

				formVars.sahapersmagazainfo_103061_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.muhasebeKodu : null);

				formVars.sahapersmagazainfo_691009_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.formatAdi : null);

				formVars.sahapersmagazainfo_773688_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.satisAlani : null);

				formVars.sahapersmagazainfo_216737_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.kasaSayisi : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
