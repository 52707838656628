import * as React from "react";
import { MagazaUrunTavsiyeKutuTanim_ScreenBase } from "./magazauruntavsiyekututanim-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";




declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class MagazaUrunTavsiyeKutuTanim_Screen extends MagazaUrunTavsiyeKutuTanim_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                    <Form form={this.props.form} name="basic"
                        initialValues={
                            {magazauruntavsiyekututanim_754460_value: this.state.TavsiyeKutuAdetiById?.at?.(0)?.urunSatisGrupId ?? undefined,
							magazauruntavsiyekututanim_858824_value: this.state.TavsiyeKutuAdetiById?.at?.(0)?.tavsiyeEdilenKutuAdeti ?? undefined
}
                        }>
                        
<div id="magazauruntavsiyekututanim_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block"} as any}>
<KRow id="81518" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="82817" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="555810" value={ReactSystemFunctions.translate(this.ml, 555810, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":700,"fontSize":"16px","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>

<KCol id="231319" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="659586" value={this.props.screenInputs.MagazaAdi ?? this.props.screenInputs.magazaadi} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":700,"fontSize":"14pt","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="575498" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="29123" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="422600" value={ReactSystemFunctions.translate(this.ml, 422600, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(0, 0, 0, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='magazauruntavsiyekututanim_754460_value' >
<KSelectBox id="754460" kuikaRef={this.magazauruntavsiyekututanim_754460_value_kuikaSelectBoxRef} options={this.state.UrunSatisGruplariSelect} placeholder={ReactSystemFunctions.translate(this.ml, 754460, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={true} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='urunSatisGrupIsim' style={{"borderTopLeftRadius":0,"borderTopRightRadius":0,"borderBottomRightRadius":0,"borderBottomLeftRadius":0,"borderColor":"rgba(235, 237, 237, 1)","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(235, 237, 237, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="444483" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="218094" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="81865" value={ReactSystemFunctions.translate(this.ml, 81865, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='magazauruntavsiyekututanim_858824_value' >
<NumberInput id="858824" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 858824, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":0,"borderTopRightRadius":0,"borderBottomRightRadius":0,"borderBottomLeftRadius":0,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(235, 237, 237, 1)","borderStyle":"solid","backgroundColor":"rgba(235, 237, 237, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="253216" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="70014" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Button id="337750" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.MagazaUrunTavsiyeKutuTanimComponent_337750_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 337750, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":4,"borderTopRightRadius":4,"borderBottomRightRadius":4,"borderBottomLeftRadius":4,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 0, 0, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"390px","height":"50px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</KCol>
</KRow>

<KRow id="320440" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="119027" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Button id="380048" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.MagazaUrunTavsiyeKutuTanimComponent_380048_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 380048, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"backgroundColor":"rgba(235, 237, 237, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"390px","height":"50px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(0, 0, 0, 1)","lineHeight":"22px"} as any}></Button>
</KCol>
</KRow>
</div>

                    </Form>
                </Spin>
              
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(MagazaUrunTavsiyeKutuTanim_Screen))))));
export { tmp as MagazaUrunTavsiyeKutuTanim_Screen };
//export default tmp;
//export { tmp as MagazaUrunTavsiyeKutuTanim_Screen };

