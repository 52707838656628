import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMagazalts_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMagazalts_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	KuserSelectByUserName: any[];
	KuserSelectByUserName_dummy: any[];
	managerMagazaVeri: any[];
	managerMagazaVeri_dummy: any[];
	managerMagazaKod: any[];
	managerMagazaKod_dummy: any[];
	storeSelect: any[];
	storeSelect_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	magazaVeriDoldur: any[];
	magazaVeriDoldur_dummy: any[];

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Magazalts_ScreenBase extends React.PureComponent<IMagazalts_ScreenProps, any> {
	magazalts_800442_value_kuikaSelectBoxRef: React.RefObject<any>;
	magazalts_540572_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"6449f753-fb7e-4cdb-81d9-2efb8c4db997","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":398554,"PropertyName":"value","Value":"Mağaza Bilgileri"},{"Id":800442,"PropertyName":"placeholder","Value":"Lütfen mağaza seçiniz.."},{"Id":540572,"PropertyName":"placeholder","Value":"Lütfen kod seçiniz.."},{"Id":534534,"PropertyName":"value","Value":"ŞİRKET"},{"Id":893569,"PropertyName":"value","Value":"MAĞAZA KODU"},{"Id":568205,"PropertyName":"value","Value":"STAND DURUMU"},{"Id":235794,"PropertyName":"value","Value":"SAHA SORUMLUSU"},{"Id":798630,"PropertyName":"value","Value":"ŞEHİR"},{"Id":697079,"PropertyName":"value","Value":"BÖLGE"},{"Id":694277,"PropertyName":"value","Value":"BAĞLI OLDUĞU DEPO"},{"Id":389013,"PropertyName":"value","Value":"Mağazadaki Çalışan Hakkında Bilgiler "},{"Id":543144,"PropertyName":"value","Value":"MAĞAZA AÇILIŞ TARİHİ"},{"Id":906029,"PropertyName":"value","Value":"MAĞAZA AÇIK ADRESİ"},{"Id":547716,"PropertyName":"value","Value":"MUHASEBE KODU"},{"Id":504013,"PropertyName":"value","Value":"FORMAT"},{"Id":972192,"PropertyName":"value","Value":"SATIŞ ALANI"},{"Id":80925,"PropertyName":"value","Value":"KASA SAYISI"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.magazalts_800442_value_kuikaSelectBoxRef = React.createRef();
		this.magazalts_540572_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        KuserSelectByUserName: [],
	        managerMagazaVeri: [],
	        managerMagazaKod: [],
	        storeSelect: [],
	        NAVIGATE: "",
	        magazaVeriDoldur: [],

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("magazalts", "mainframe_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.magazaltsPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("magazalts", "mainframe_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("magazalts", "mainframe_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.magazaltsPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.magazaltsPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    magazaltsPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "magazalts/magazaltsPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.KuserSelectByUserName = result?.data.kuserSelectByUserName;
		stateVars.managerMagazaVeri = result?.data.managerMagazaVeri;
		
		formVars.magazalts_800442_options = stateVars.managerMagazaVeri;
		stateVars.managerMagazaKod = result?.data.managerMagazaKod;
		
		formVars.magazalts_540572_options = stateVars.managerMagazaKod;
		stateVars.storeSelect = result?.data.storeSelect;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.magazaltsPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	magazaltsPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_800442 = this.state.managerMagazaVeri;
			stateVars.dataSource_800442 = this.state.managerMagazaVeri;
			
			stateVars.dataSource_540572 = this.state.managerMagazaKod;
			stateVars.dataSource_540572 = this.state.managerMagazaKod;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		magazaltsComponent_986417_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("magazaDuzenle", "veriId", ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazalts_800442_value", "value", "managerMagazaVeri", "id", "id")));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "magazalts", "magazaDuzenle", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "156566", null, "right", null, "350px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    magazaltsComponent_800442_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazalts_800442_value", "value", "managerMagazaVeri", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "magazalts/magazaltsComponent_800442_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.magazaVeriDoldur = result?.data.magazaVeriDoldur;
			formVars.magazalts_490137_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAdi : null);
			formVars.magazalts_352251_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sirket : null);
			formVars.magazalts_542029_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaKodu : null);
			formVars.magazalts_811328_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.standDurumu : null);
			formVars.magazalts_268083_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sahaSorumlusu : null);
			formVars.magazalts_30624_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sehir : null);
			formVars.magazalts_899102_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.bolge : null);
			formVars.magazalts_390124_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.depo : null);
			formVars.magazalts_409260_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);
			formVars.magazalts_468424_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);
			formVars.magazalts_995904_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.adres : null);
			formVars.magazalts_331883_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.muhasebeKodu : null);
			formVars.magazalts_223221_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.formatAdi : null);
			formVars.magazalts_436950_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.satisAlani : null);
			formVars.magazalts_645667_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.kasaSayisi : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.magazaltsComponent_800442_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		magazaltsComponent_800442_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.magazalts_490137_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAdi : null);

				formVars.magazalts_352251_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sirket : null);

				formVars.magazalts_542029_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaKodu : null);

				formVars.magazalts_811328_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.standDurumu : null);

				formVars.magazalts_268083_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sahaSorumlusu : null);

				formVars.magazalts_30624_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sehir : null);

				formVars.magazalts_899102_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.bolge : null);

				formVars.magazalts_390124_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.depo : null);

				formVars.magazalts_409260_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);

				formVars.magazalts_468424_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);

				formVars.magazalts_995904_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.adres : null);

				formVars.magazalts_331883_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.muhasebeKodu : null);

				formVars.magazalts_223221_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.formatAdi : null);

				formVars.magazalts_436950_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.satisAlani : null);

				formVars.magazalts_645667_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.kasaSayisi : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    magazaltsComponent_540572_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "magazalts_540572_value", "value", "managerMagazaKod", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "magazalts/magazaltsComponent_540572_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.magazaVeriDoldur = result?.data.magazaVeriDoldur;
			formVars.magazalts_490137_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAdi : null);
			formVars.magazalts_352251_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sirket : null);
			formVars.magazalts_542029_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaKodu : null);
			formVars.magazalts_811328_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.standDurumu : null);
			formVars.magazalts_268083_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sahaSorumlusu : null);
			formVars.magazalts_30624_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.sehir : null);
			formVars.magazalts_899102_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.bolge : null);
			formVars.magazalts_390124_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.depo : null);
			formVars.magazalts_409260_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);
			formVars.magazalts_468424_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);
			formVars.magazalts_995904_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.adres : null);
			formVars.magazalts_331883_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.muhasebeKodu : null);
			formVars.magazalts_223221_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.formatAdi : null);
			formVars.magazalts_436950_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.satisAlani : null);
			formVars.magazalts_645667_value = ReactSystemFunctions.toString(this, stateVars.magazaVeriDoldur?.length > 0 ? stateVars.magazaVeriDoldur[0]?.kasaSayisi : null);
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.magazaltsComponent_540572_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		magazaltsComponent_540572_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.magazalts_490137_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAdi : null);

				formVars.magazalts_352251_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sirket : null);

				formVars.magazalts_542029_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaKodu : null);

				formVars.magazalts_811328_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.standDurumu : null);

				formVars.magazalts_268083_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sahaSorumlusu : null);

				formVars.magazalts_30624_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.sehir : null);

				formVars.magazalts_899102_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.bolge : null);

				formVars.magazalts_390124_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.depo : null);

				formVars.magazalts_409260_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaPersonelBilgi : null);

				formVars.magazalts_468424_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.magazaAcilisTarihi : null);

				formVars.magazalts_995904_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.adres : null);

				formVars.magazalts_331883_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.muhasebeKodu : null);

				formVars.magazalts_223221_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.formatAdi : null);

				formVars.magazalts_436950_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.satisAlani : null);

				formVars.magazalts_645667_value = ReactSystemFunctions.toString(this, this.state.magazaVeriDoldur?.length > 0 ? this.state.magazaVeriDoldur[0]?.kasaSayisi : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [156566] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.magazaltsPageInit();
		}

    }
}
