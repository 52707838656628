import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IGunlukZiyaretRotalari_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IGunlukZiyaretRotalari_List_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	GunlukZiyaretRotalariCount: any[];
	GunlukZiyaretRotalariCount_dummy: any[];
	GunlukZiyaretRotalariSearch: any[];
	GunlukZiyaretRotalariSearch_dummy: any[];
	SetValueOf: any;
	SetValueOf_dummy: any;
	GunlukZiyaretRotalariDeleteById: number;
	GunlukZiyaretRotalariDeleteById_dummy: number;


}

export class GunlukZiyaretRotalari_List_ScreenBase extends React.PureComponent<IGunlukZiyaretRotalari_List_ScreenProps, any> {
	gunlukziyaretrotalari_list_399769_value_kuikaTableRef: React.RefObject<any>;
	gunlukziyaretrotalari_list_172599_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"f5d0e535-e462-4708-9b01-86e7710bfb97","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"Gunluk ziyaret rotalari List"},{"Id":416063,"PropertyName":"placeholder","Value":"Search..."},{"Id":814413,"PropertyName":"label","Value":"Add New"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":9612779,"PropertyName":"title","Value":"Week"},{"Id":5837299,"PropertyName":"value","Value":"[datafield:week]"},{"Id":1742258,"PropertyName":"title","Value":"Day1"},{"Id":7349940,"PropertyName":"value","Value":"[datafield:day1]"},{"Id":2683348,"PropertyName":"title","Value":"Row1"},{"Id":9985518,"PropertyName":"value","Value":"[datafield:row1]"},{"Id":5403228,"PropertyName":"title","Value":"Realization week"},{"Id":2577313,"PropertyName":"value","Value":"[datafield:realizationweek]"},{"Id":4772220,"PropertyName":"title","Value":"Realization day"},{"Id":3426065,"PropertyName":"value","Value":"[datafield:realizationday]"},{"Id":4779400,"PropertyName":"title","Value":"Comments"},{"Id":3218291,"PropertyName":"value","Value":"[datafield:comments]"},{"Id":4696179,"PropertyName":"title","Value":"Islem"},{"Id":2503873,"PropertyName":"value","Value":"[datafield:islem]"},{"Id":8950422,"PropertyName":"title","Value":"Date"},{"Id":7050091,"PropertyName":"value","Value":"[datafield:date]"},{"Id":9622681,"PropertyName":"title","Value":"date1"},{"Id":5775134,"PropertyName":"value","Value":"[datafield:date1]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.gunlukziyaretrotalari_list_399769_value_kuikaTableRef = React.createRef();
		this.gunlukziyaretrotalari_list_172599_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        NAVIGATE: "",
	        GunlukZiyaretRotalariCount: [],
	        GunlukZiyaretRotalariSearch: [],
	        SetValueOf: "",
	        GunlukZiyaretRotalariDeleteById: 0,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_list", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.GunlukZiyaretRotalari_ListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_list", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_list", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.GunlukZiyaretRotalari_ListPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    GunlukZiyaretRotalari_ListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
			searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
			currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "current", "", "", "")), "number"),
			pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "pageSize", "", "", "")), "number")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_List/GunlukZiyaretRotalari_ListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.GunlukZiyaretRotalariCount = result?.data.gunlukZiyaretRotalariCount;
		stateVars.GunlukZiyaretRotalariSearch = result?.data.gunlukZiyaretRotalariSearch;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	GunlukZiyaretRotalari_ListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_399769 = this.state.GunlukZiyaretRotalariSearch;
			formVars.gunlukziyaretrotalari_list_172599_total = ReactSystemFunctions.value(this, this.state.GunlukZiyaretRotalariCount?.length > 0 ? this.state.GunlukZiyaretRotalariCount[0]?.count : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		GunlukZiyaretRotalari_ListComponent_981633_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "GunlukZiyaretRotalari_List", "LeftMenu", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "139195", null, "left", null, "360px", "", false);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		GunlukZiyaretRotalari_ListComponent_416063_onPressEnter = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "gunlukziyaretrotalari_list_172599_value", 1, null);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_416063_onPressEnter1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    GunlukZiyaretRotalari_ListComponent_416063_onPressEnter1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "pageSize", "", "", "")), "number")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_List/GunlukZiyaretRotalari_ListComponent_416063_onPressEnter1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.GunlukZiyaretRotalariCount = result?.data.gunlukZiyaretRotalariCount;
			stateVars.GunlukZiyaretRotalariSearch = result?.data.gunlukZiyaretRotalariSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_416063_onPressEnter2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		GunlukZiyaretRotalari_ListComponent_416063_onPressEnter2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.GunlukZiyaretRotalariSearch;
				formVars.gunlukziyaretrotalari_list_172599_total = ReactSystemFunctions.value(this, this.state.GunlukZiyaretRotalariCount?.length > 0 ? this.state.GunlukZiyaretRotalariCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		GunlukZiyaretRotalari_ListComponent_416063_onBlur = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "gunlukziyaretrotalari_list_172599_value", 1, null);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_416063_onBlur1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    GunlukZiyaretRotalari_ListComponent_416063_onBlur1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "pageSize", "", "", "")), "number")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_List/GunlukZiyaretRotalari_ListComponent_416063_onBlur1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.GunlukZiyaretRotalariCount = result?.data.gunlukZiyaretRotalariCount;
			stateVars.GunlukZiyaretRotalariSearch = result?.data.gunlukZiyaretRotalariSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_416063_onBlur2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		GunlukZiyaretRotalari_ListComponent_416063_onBlur2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.GunlukZiyaretRotalariSearch;
				formVars.gunlukziyaretrotalari_list_172599_total = ReactSystemFunctions.value(this, this.state.GunlukZiyaretRotalariCount?.length > 0 ? this.state.GunlukZiyaretRotalariCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		GunlukZiyaretRotalari_ListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("GunlukZiyaretRotalari_Form", "Id", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "GunlukZiyaretRotalari_List", "GunlukZiyaretRotalari_Form", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		GunlukZiyaretRotalari_ListComponent_911079_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("GunlukZiyaretRotalari_View", "Id", ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "GunlukZiyaretRotalari_List", "GunlukZiyaretRotalari_View", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "336999", null, "right", null, "450px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		GunlukZiyaretRotalari_ListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("GunlukZiyaretRotalari_Form", "Id", ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "GunlukZiyaretRotalari_List", "GunlukZiyaretRotalari_Form", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "450px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    GunlukZiyaretRotalari_ListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_399769_value", "id"), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "current", "", "", "")), "number"),
				pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_List/GunlukZiyaretRotalari_ListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.GunlukZiyaretRotalariDeleteById = result?.data.gunlukZiyaretRotalariDeleteById;
			stateVars.GunlukZiyaretRotalariCount = result?.data.gunlukZiyaretRotalariCount;
			stateVars.GunlukZiyaretRotalariSearch = result?.data.gunlukZiyaretRotalariSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		GunlukZiyaretRotalari_ListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.GunlukZiyaretRotalariSearch;
				formVars.gunlukziyaretrotalari_list_172599_total = ReactSystemFunctions.value(this, this.state.GunlukZiyaretRotalariCount?.length > 0 ? this.state.GunlukZiyaretRotalariCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    GunlukZiyaretRotalari_ListComponent_172599_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "gunlukziyaretrotalari_list_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_List/GunlukZiyaretRotalari_ListComponent_172599_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.GunlukZiyaretRotalariCount = result?.data.gunlukZiyaretRotalariCount;
			stateVars.GunlukZiyaretRotalariSearch = result?.data.gunlukZiyaretRotalariSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ListComponent_172599_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		GunlukZiyaretRotalari_ListComponent_172599_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.GunlukZiyaretRotalariSearch;
				formVars.gunlukziyaretrotalari_list_172599_total = ReactSystemFunctions.value(this, this.state.GunlukZiyaretRotalariCount?.length > 0 ? this.state.GunlukZiyaretRotalariCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 336999, 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.GunlukZiyaretRotalari_ListPageInit();
		}

    }
}
