import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMagazaTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMagazaTanim_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	SehirSelect: any[];
	SehirSelect_dummy: any[];
	customersSelect: any[];
	customersSelect_dummy: any[];
	MagazaById: any[];
	MagazaById_dummy: any[];
	GoBack: any;
	GoBack_dummy: any;


}

export class MagazaTanim_ScreenBase extends React.PureComponent<IMagazaTanim_ScreenProps, any> {
	magazatanim_304679_value_kuikaSelectBoxRef: React.RefObject<any>;
	magazatanim_388888_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"f691527f-0ca6-4c18-af8f-adad1f9d5a34","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":555810,"PropertyName":"value","Value":"Mağaza Tanımlama Formu"},{"Id":422600,"PropertyName":"value","Value":"Mağaza İsmi"},{"Id":195882,"PropertyName":"placeholder","Value":"İsim Yazınız..."},{"Id":646264,"PropertyName":"value","Value":"Mağaza Kodu"},{"Id":647275,"PropertyName":"placeholder","Value":"İsim Yazınız..."},{"Id":596523,"PropertyName":"value","Value":"Şehir"},{"Id":304679,"PropertyName":"placeholder","Value":"Şehir Seçiniz..."},{"Id":81865,"PropertyName":"value","Value":"Ana Müşteri"},{"Id":388888,"PropertyName":"placeholder","Value":"Müşteri Seçiniz..."},{"Id":337750,"PropertyName":"label","Value":"Kaydet"},{"Id":186317,"PropertyName":"label","Value":"İPTAL"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.magazatanim_304679_value_kuikaSelectBoxRef = React.createRef();
		this.magazatanim_388888_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        SehirSelect: [],
	        customersSelect: [],
	        MagazaById: [],
	        GoBack: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("magazatanim", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.MagazaTanimPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("magazatanim", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("magazatanim", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MagazaTanimPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
magazatanim_195882_value: this.state.MagazaById?.at?.(0)?.magazaAdi ?? undefined,
magazatanim_647275_value: this.state.MagazaById?.at?.(0)?.magazaAdi ?? undefined,
magazatanim_304679_value: this.state.SehirSelect?.at?.(0)?.id ?? undefined,
magazatanim_388888_value: this.state.customersSelect?.at?.(0)?.sirketAdi ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    MagazaTanimPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MagazaId ?? this.props.screenInputs.magazaid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MagazaTanim/MagazaTanimPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SehirSelect = result?.data.sehirSelect;
		
		formVars.magazatanim_304679_options = stateVars.SehirSelect;
		stateVars.customersSelect = result?.data.customersSelect;
		
		formVars.magazatanim_388888_value = stateVars.customersSelect?.length > 0 ? stateVars.customersSelect[0]?.sirketAdi : null;
		formVars.magazatanim_388888_options = stateVars.customersSelect;
		stateVars.MagazaById = result?.data.magazaById;
		formVars.magazatanim_195882_value = ReactSystemFunctions.toString(this, stateVars.MagazaById?.length > 0 ? stateVars.MagazaById[0]?.magazaAdi : null);
		formVars.magazatanim_647275_value = ReactSystemFunctions.toString(this, stateVars.MagazaById?.length > 0 ? stateVars.MagazaById[0]?.magazaAdi : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MagazaTanimPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MagazaTanimPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.magazatanim_195882_value = ReactSystemFunctions.toString(this, this.state.MagazaById?.length > 0 ? this.state.MagazaById[0]?.magazaAdi : null);

			formVars.magazatanim_647275_value = ReactSystemFunctions.toString(this, this.state.MagazaById?.length > 0 ? this.state.MagazaById[0]?.magazaAdi : null);

			
			stateVars.dataSource_304679 = this.state.SehirSelect;
			stateVars.dataSource_304679 = this.state.SehirSelect;
			formVars.magazatanim_388888_value = ReactSystemFunctions.toString(this, this.state.customersSelect?.length > 0 ? this.state.customersSelect[0]?.sirketAdi : null);

			formVars.magazatanim_388888_value = ReactSystemFunctions.toString(this, this.state.customersSelect?.length > 0 ? this.state.customersSelect[0]?.sirketAdi : null);

			stateVars.dataSource_388888 = this.state.customersSelect;
			stateVars.dataSource_388888 = this.state.customersSelect;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		MagazaTanimComponent_337750_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MagazaTanimComponent_186317_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MagazaTanimComponent_304679_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
