import * as React from "react";
import { SahaPersSatisDagilim_ScreenBase } from "./sahaperssatisdagilim-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";
import {MainFrame_Screen} from "../mainframe/mainframe";



declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class SahaPersSatisDagilim_Screen extends SahaPersSatisDagilim_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              <MainFrame_Screen form={this.props.form} initialValues={{sahaperssatisdagilim_815630_value: undefined
}} screenInputs={this.props.screenInputs} setMasterPageInitLoaded={this.setMasterPageInitLoaded} setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded} isMasterPageInitLoaded={this.state.isMasterPageInitLoaded} isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded} >
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                        
<div id="sahaperssatisdagilim_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block","overflow":"auto","minHeight":"calc(100vh - 70px - 60px - 0px - 0px)","maxHeight":"calc(100vh - 70px - 60px - 0px - 0px)","height":"calc(100vh - 70px - 60px - 0px - 0px)"} as any}>
<KRow id="470253" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="64038" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Label id="227062" value={this.state.tarihuret?.at?.(0)?.magazaAdi ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"2em","color":"rgba(0, 0, 0, 1)"} as any}></Label>
</KCol>

<KCol id="749446" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":9,"borderTopRightRadius":9,"borderBottomRightRadius":9,"borderBottomLeftRadius":9,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Form.Item className='kFormItem' name='sahaperssatisdagilim_815630_value' >
<KSelectBox id="815630" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.sahaPersSatisDagilimComponent_815630_onChange()} } kuikaRef={this.sahaperssatisdagilim_815630_value_kuikaSelectBoxRef} options={this.state.sorumluyaGoreMagaza} placeholder={ReactSystemFunctions.translate(this.ml, 815630, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={true} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='magazaAdi' style={{"borderTopLeftRadius":0,"borderTopRightRadius":0,"borderBottomRightRadius":0,"borderBottomLeftRadius":0,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(235, 237, 237, 1)","borderStyle":"solid","backgroundColor":"rgba(235, 237, 237, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></KSelectBox>
</Form.Item>

<KRow id="696934" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<KCol id="911910" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<HorizontalStack id="645048" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="95118" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(82, 196, 26, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="764982" value={ReactSystemFunctions.translate(this.ml, 764982, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="486141" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="147871" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(19, 194, 194, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="165615" value={ReactSystemFunctions.translate(this.ml, 165615, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="935091" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="463950" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(114, 46, 209, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="26246" value={ReactSystemFunctions.translate(this.ml, 26246, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="189382" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="618903" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(140, 140, 140, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="95066" value={ReactSystemFunctions.translate(this.ml, 95066, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="70708" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="819528" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(24, 144, 255, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="1238" value={ReactSystemFunctions.translate(this.ml, 1238, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>

<HorizontalStack id="388660" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="551617" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(239, 111, 40, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="463749" value={ReactSystemFunctions.translate(this.ml, 463749, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>

<HorizontalStack id="606275" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="1962" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(245, 34, 45, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="427392" value={ReactSystemFunctions.translate(this.ml, 427392, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>

<HorizontalStack id="29341" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="707501" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(235, 47, 150, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="565703" value={ReactSystemFunctions.translate(this.ml, 565703, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="877105" direction="horizontal" size={1} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}>
<Icon id="738316" iconName="yard" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":3,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(70, 39, 92, 1)","letterSpacing":"1px"} as any}></Icon>

<Label id="816612" value={ReactSystemFunctions.translate(this.ml, 816612, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"width":"140px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(68, 68, 68, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>
</KCol>
</KRow>

<MixedChart id="159130" options={this.state.tarihuret} dataxaxisesfield="gunSayisi" options={this.state.dataSource_159130} style={{} as any}>
<MixedChartLineItem id="71693" options={this.state.kalanchoe6Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(82, 196, 26, 1)" type="line" label="Queen Kalanchoe 10,5" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_71693} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="517529" options={this.state.kalanchoe10Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(245, 34, 45, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_517529} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="771638" options={this.state.euphorbia10Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(114, 46, 209, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_771638} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="172266" options={this.state.rose10Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(19, 194, 194, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_172266} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="84552" options={this.state.poinsettiaGrafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(140, 140, 140, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_84552} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="837896" options={this.state.succulent5Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(250, 84, 28, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_837896} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="130146" options={this.state.succulent8Grafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(235, 47, 150, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_130146} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="773199" options={this.state.onepackGrafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(70, 39, 92, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_773199} style={{} as any}></MixedChartLineItem>

<MixedChartLineItem id="397830" options={this.state.campanulaGrafik} dataxaxisvaluefield="toplamAdet" dataxaxisfield="gunSayisi" dataxaxistooltipfield="tarihUzun" datavaluetooltipfield="toplamAdet" color="rgba(24, 144, 255, 1)" type="line" dashSpacing={0} dashLength={0} lineWidth={1} options={this.state.dataSource_397830} style={{} as any}></MixedChartLineItem>
</MixedChart>
</KCol>
</KRow>
</div>

                    
                </Spin>
              </MainFrame_Screen>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SahaPersSatisDagilim_Screen))))));
export { tmp as SahaPersSatisDagilim_Screen };
//export default tmp;
//export { tmp as SahaPersSatisDagilim_Screen };

