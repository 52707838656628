const screenInputData = {
  magazaDuzenle: [{"name":"veriId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	MagazaTanim: [{"name":"MagazaId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	addStore: [{"name":"storeId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	MagazaUrunSatisTanim: [{"name":"MagazaUrunSatisId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MagazaAdi","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"MagazaId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SahaZiyaretFormucopy: [{"name":"verigetir","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	GunlukZiyaretRotalari_View: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	SahaZiyaretFormu2: [{"name":"verigetir","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	KullaniciTanim: [{"name":"UserId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	GunlukZiyaretRotalari_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	MagazaUrunTavsiyeKutuTanim: [{"name":"TavsiyeKutuAdetiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MagazaAdi","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"MagazaId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	UrunSatisGrupTanim: [{"name":"UrunSatisGrupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Rut: [{"name":"VeriGetir","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SahaZiyaretFormu: [{"name":"verigetir","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	magazalarTablosu_View: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
