import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IAuthentication_Forgot_Password_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IAuthentication_Forgot_Password_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	ForgotPassword: string;
	ForgotPassword_dummy: string;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	goToVerificationCodeActivity: any;
	goToVerificationCodeActivity_dummy: any;


}

export class Authentication_Forgot_Password_ScreenBase extends React.PureComponent<IAuthentication_Forgot_Password_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"6ee4bdc5-13f6-4f5d-877b-81c083428d25","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":574431,"PropertyName":"value","Value":"Şifrenizi mi unuttunuz?"},{"Id":900580,"PropertyName":"value","Value":"Şifrenizi sıfırlamak için e-posta adresinizi girin."},{"Id":405510,"PropertyName":"placeholder","Value":"Mail adresin..."},{"Id":611276,"PropertyName":"label","Value":"Sıfırlama Bağlantısını Gönder"},{"Id":883899,"PropertyName":"value","Value":"Geri dön"},{"Id":520648,"PropertyName":"value","Value":"Giriş yap"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        ForgotPassword: "",
	        NAVIGATE: "",
	        goToVerificationCodeActivity: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("authentication_forgot_password", "");
            return;
        }
        
        
        if (true){
            
        }
        
        window.addEventListener("keydown", this.onKeyDown)
        
        KuikaAppManager.calculateAndSetBodyHeight("authentication_forgot_password", "");
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        
        
    }

    

        

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if(keyCode === 13){
      var element = document.getElementById("611276");
      if (element && element.click)
            {
                element.click();
            }
        }
    }

    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("authentication_forgot_password", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      


		Authentication_Forgot_PasswordComponent_611276_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "authentication_forgot_password_405510_value", "value", "", "", "")), null),
			message: "This field is required",
			formName: "authentication_forgot_password_405510_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isValidEmail(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "authentication_forgot_password_405510_value", "value", "", "", "")), null),
			message: "Geçerli bir mail adresi giriniz.",
			formName: "authentication_forgot_password_405510_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.ForgotPassword = await ReactSystemFunctions.forgotPassword(this,ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "authentication_forgot_password_405510_value", "value", "", "", "")),false,null);
					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.Authentication_Forgot_PasswordComponent_611276_onClick1_, "Authentication_Forgot_Password", "Authentication_Verification_Code", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		Authentication_Forgot_PasswordComponent_611276_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.tmpBoolResult = await ReactSystemFunctions.goToVerificationCodeActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }



		Authentication_Forgot_PasswordComponent_520648_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Authentication_Forgot_Password", "Signin", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [, ] as number[];

    }
}
