import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IGunlukZiyaretRotalari_View_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IGunlukZiyaretRotalari_View_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	SelectGunlukZiyaretRotalari: any[];
	SelectGunlukZiyaretRotalari_dummy: any[];


}

export class GunlukZiyaretRotalari_View_ScreenBase extends React.PureComponent<IGunlukZiyaretRotalari_View_ScreenProps, any> {

    ml=[{"Id":"c27bb49b-e84b-2132-b611-3806f3391b29","Name":"tr_TR","ShortName":"tr","IsDefault":true,"LanguagePhrases":[]},{"Id":"06d2c229-146e-4155-8c55-a1684b084d7f","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"2d4e0d48-b411-4a0b-a852-241b0245bdbb","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Gunluk ziyaret rotalari View"},{"Id":3141859,"PropertyName":"value","Value":"Account"},{"Id":4498665,"PropertyName":"value","Value":"City"},{"Id":2935501,"PropertyName":"value","Value":"code"},{"Id":7238542,"PropertyName":"value","Value":"Comments"},{"Id":5085626,"PropertyName":"value","Value":"Date"},{"Id":5318000,"PropertyName":"value","Value":"date1"},{"Id":3780047,"PropertyName":"value","Value":"Day1"},{"Id":2371235,"PropertyName":"value","Value":"District"},{"Id":4904362,"PropertyName":"value","Value":"Division"},{"Id":2463504,"PropertyName":"value","Value":"Formula"},{"Id":9658595,"PropertyName":"value","Value":"Formula1"},{"Id":6795381,"PropertyName":"value","Value":"Freq"},{"Id":6712916,"PropertyName":"value","Value":"Islem"},{"Id":8345793,"PropertyName":"value","Value":"RealizationDay"},{"Id":2541582,"PropertyName":"value","Value":"RealizationWeek"},{"Id":2314411,"PropertyName":"value","Value":"Row1"},{"Id":2112755,"PropertyName":"value","Value":"StartWeek"},{"Id":5445858,"PropertyName":"value","Value":"Status"},{"Id":8548809,"PropertyName":"value","Value":"Store"},{"Id":1790289,"PropertyName":"value","Value":"TMR"},{"Id":5667860,"PropertyName":"value","Value":"Type1"},{"Id":7362524,"PropertyName":"value","Value":"UnitQty"},{"Id":4477571,"PropertyName":"value","Value":"UnitType"},{"Id":7442565,"PropertyName":"value","Value":"Week"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        SelectGunlukZiyaretRotalari: [],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_view", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.GunlukZiyaretRotalari_ViewPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_view", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("gunlukziyaretrotalari_view", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.GunlukZiyaretRotalari_ViewPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    GunlukZiyaretRotalari_ViewPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "GunlukZiyaretRotalari_View/GunlukZiyaretRotalari_ViewPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SelectGunlukZiyaretRotalari = result?.data.selectGunlukZiyaretRotalari;
		formVars.gunlukziyaretrotalari_view_5327043_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.account : null);
		formVars.gunlukziyaretrotalari_view_7234303_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.city : null);
		formVars.gunlukziyaretrotalari_view_4536809_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.code : null);
		formVars.gunlukziyaretrotalari_view_8433930_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.comments : null);
		formVars.gunlukziyaretrotalari_view_9516487_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.date : null);
		formVars.gunlukziyaretrotalari_view_1972127_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.date1 : null);
		formVars.gunlukziyaretrotalari_view_2025269_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.day1 : null);
		formVars.gunlukziyaretrotalari_view_6670377_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.district : null);
		formVars.gunlukziyaretrotalari_view_4750942_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.division : null);
		formVars.gunlukziyaretrotalari_view_1265669_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.formula : null);
		formVars.gunlukziyaretrotalari_view_2996728_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.formula1 : null);
		formVars.gunlukziyaretrotalari_view_4285801_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.freq : null);
		formVars.gunlukziyaretrotalari_view_1698484_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.islem : null);
		formVars.gunlukziyaretrotalari_view_2172767_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.realizationDay : null);
		formVars.gunlukziyaretrotalari_view_5578471_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.realizationWeek : null);
		formVars.gunlukziyaretrotalari_view_4927984_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.row1 : null);
		formVars.gunlukziyaretrotalari_view_9752695_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.startWeek : null);
		formVars.gunlukziyaretrotalari_view_4701017_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.status : null);
		formVars.gunlukziyaretrotalari_view_4819496_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.store : null);
		formVars.gunlukziyaretrotalari_view_4458394_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.tmr : null);
		formVars.gunlukziyaretrotalari_view_8298297_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.type1 : null);
		formVars.gunlukziyaretrotalari_view_4001523_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.unitQty : null);
		formVars.gunlukziyaretrotalari_view_5402246_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.unitType : null);
		formVars.gunlukziyaretrotalari_view_9409716_value = ReactSystemFunctions.toString(this, stateVars.SelectGunlukZiyaretRotalari?.length > 0 ? stateVars.SelectGunlukZiyaretRotalari[0]?.week : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.GunlukZiyaretRotalari_ViewPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	GunlukZiyaretRotalari_ViewPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.gunlukziyaretrotalari_view_5327043_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.account : null);

			formVars.gunlukziyaretrotalari_view_7234303_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.city : null);

			formVars.gunlukziyaretrotalari_view_4536809_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.code : null);

			formVars.gunlukziyaretrotalari_view_8433930_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.comments : null);

			formVars.gunlukziyaretrotalari_view_9516487_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.date : null);

			formVars.gunlukziyaretrotalari_view_1972127_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.date1 : null);

			formVars.gunlukziyaretrotalari_view_2025269_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.day1 : null);

			formVars.gunlukziyaretrotalari_view_6670377_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.district : null);

			formVars.gunlukziyaretrotalari_view_4750942_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.division : null);

			formVars.gunlukziyaretrotalari_view_1265669_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.formula : null);

			formVars.gunlukziyaretrotalari_view_2996728_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.formula1 : null);

			formVars.gunlukziyaretrotalari_view_4285801_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.freq : null);

			formVars.gunlukziyaretrotalari_view_1698484_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.islem : null);

			formVars.gunlukziyaretrotalari_view_2172767_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.realizationDay : null);

			formVars.gunlukziyaretrotalari_view_5578471_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.realizationWeek : null);

			formVars.gunlukziyaretrotalari_view_4927984_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.row1 : null);

			formVars.gunlukziyaretrotalari_view_9752695_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.startWeek : null);

			formVars.gunlukziyaretrotalari_view_4701017_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.status : null);

			formVars.gunlukziyaretrotalari_view_4819496_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.store : null);

			formVars.gunlukziyaretrotalari_view_4458394_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.tmr : null);

			formVars.gunlukziyaretrotalari_view_8298297_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.type1 : null);

			formVars.gunlukziyaretrotalari_view_4001523_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.unitQty : null);

			formVars.gunlukziyaretrotalari_view_5402246_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.unitType : null);

			formVars.gunlukziyaretrotalari_view_9409716_value = ReactSystemFunctions.toString(this, this.state.SelectGunlukZiyaretRotalari?.length > 0 ? this.state.SelectGunlukZiyaretRotalari[0]?.week : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		GunlukZiyaretRotalari_ViewComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
